import { RedactionAction, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DropdownMenuItem } from "ts/dropdown";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RedactionRequestListSortingParameter } from "ts/enums/sorting";
import { RequestsView } from "ts/enums/requestListView";
import { fetchRedactionRequests } from "./thunks";
import { RedactionRequest } from "ts/redactionRequest";

type RedactionRequestListSorting = {
  sortColumn: RedactionRequestListSortingParameter;
  sortOrder: SortingOrder;
};
type RedactionRequestListState = {
  redactionRequests: RedactionRequest[];
  currentPage: number;
  totalRedactionRequestCount: number;
  currentRedactionRequestCount: number;
  searchField: string;
  listView: RequestsView;
  sortColumn: RedactionRequestListSortingParameter;
  sortOrder: SortingOrder;
  isSearchReset: boolean;
  currentStatusFilters: string[];
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  allStatusFilters: string[];
  currentEditedRow: RedactionRequest;
  dropdownMenuContent: DropdownMenuItem[];
  isLoading: boolean;
  selectedRedactionRequests: RedactionRequest[];
  bulkActionToBlock: RedactionAction;
  requestsUniqueCommentIds: number[];
};

const initialState: RedactionRequestListState = {
  redactionRequests: [],
  currentPage: 1,
  totalRedactionRequestCount: 0,
  currentRedactionRequestCount: 0,
  listView: RequestsView.MyRequests,
  searchField: "",
  currentStatusFilters: [],
  sortColumn: RedactionRequestListSortingParameter.RequestDate,
  sortOrder: SortingOrder.DESC,
  isSearchReset: false,
  emptyStateType: EmptyStateType.noListResults,
  allStatusFilters: [],
  showDeleteConfirmation: false,
  dropdownMenuContent: null,
  currentEditedRow: null,
  isLoading: true,
  selectedRedactionRequests: [],
  requestsUniqueCommentIds: [],
  bulkActionToBlock: null,
};

const RedactionRequestListSlice = createSlice({
  name: "redactionRequestListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAllStatusFilters: (state, action: PayloadAction<string[]>) => {
      state.allStatusFilters = action.payload;
    },
    toggleSelectAll: (state) => {
      const isAllSelected = state.redactionRequests.every((req) => req.isSelected);
      state.redactionRequests = state.redactionRequests.map((req) => ({
        ...req,
        isSelected: !isAllSelected,
      }));
      if (isAllSelected) {
        state.selectedRedactionRequests = state.selectedRedactionRequests.filter(
          (selectedReq) =>
            !state.redactionRequests.map((req) => req.requestId).includes(selectedReq.requestId)
        );
      } else {
        state.selectedRedactionRequests = state.redactionRequests;
      }
      state.bulkActionToBlock = null;
    },
    toggleRedactionRequestSelection: (state, action: PayloadAction<number>) => {
      const redactionIndex = state.redactionRequests.findIndex(
        (req) => req.requestId === action.payload
      );
      state.redactionRequests[redactionIndex].isSelected =
        !state.redactionRequests[redactionIndex].isSelected;
      state.redactionRequests[redactionIndex].isSelected
        ? state.selectedRedactionRequests.push(
            state.redactionRequests.find((req) => req.requestId === action.payload)
          )
        : state.selectedRedactionRequests.splice(
            state.selectedRedactionRequests.findIndex((el) => el.requestId === action.payload),
            1
          );
      state.bulkActionToBlock = null;
    },
    clearState: () => initialState,
    clearSelection: (state) => {
      state.selectedRedactionRequests = [];
      state.redactionRequests = state.redactionRequests.map((req) => ({
        ...req,
        isSelected: false,
      }));
    },
    setCurrentStatusFilters: (state, action: PayloadAction<string[]>) => {
      state.currentStatusFilters = action.payload;
    },
    setIsSearchReset: (state, action: PayloadAction<boolean>) => {
      state.isSearchReset = action.payload;
    },
    setListView: (state, action: PayloadAction<RequestsView>) => {
      state.listView = action.payload;
      state.selectedRedactionRequests = [];
      state.bulkActionToBlock = null;
    },
    updateRedactionRequestListSorting: (
      state,
      action: PayloadAction<RedactionRequestListSorting>
    ) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setCurrentEditedRow: (state, action: PayloadAction<RedactionRequest>) => {
      state.currentEditedRow = action.payload;
    },
    setDropdownMenuContent: (state, action: PayloadAction<DropdownMenuItem[]>) => {
      state.dropdownMenuContent = action.payload;
    },
    setBulkActionToBlock: (state, action: PayloadAction<RedactionAction>) => {
      state.bulkActionToBlock = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    changeSelectedDropdownItem: (state, action: PayloadAction<RequestsView>) => {
      state.dropdownMenuContent = state.dropdownMenuContent.map((item) => ({
        ...item,
        isActive: item.value === action.payload,
      }));
    },
    changeListView: (state, action: PayloadAction<RequestsView>) => {
      state.currentPage = 1;
      state.listView = action.payload;
      state.sortOrder = SortingOrder.DESC;
      state.sortColumn = RedactionRequestListSortingParameter.RequestDate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedactionRequests.fulfilled, (state, action) => {
      state.isLoading = false;
      state.redactionRequests = action.payload.redactionRequests.map((req) => ({
        ...req,
        isSelected: !!state.selectedRedactionRequests.find(
          (selectedReq) => req.requestId === selectedReq.requestId
        ),
      }));
      state.totalRedactionRequestCount = action.payload.totalCount;
      state.currentRedactionRequestCount = action.payload.itemCount;
      state.requestsUniqueCommentIds = Array.from(
        new Set(action.payload.redactionRequests.map((req) => req.commentId))
      );
    });
    builder.addCase(fetchRedactionRequests.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  updateRedactionRequestListSorting,
  setCurrentPage,
  setSearchField,
  setIsSearchReset,
  setCurrentEditedRow,
  setCurrentStatusFilters,
  toggleRedactionRequestSelection,
  setIsLoading,
  setListView,
  setAllStatusFilters,
  changeSelectedDropdownItem,
  toggleSelectAll,
  changeListView,
  setEmptyStateType,
  setBulkActionToBlock,
  setDropdownMenuContent,
  clearState,
  clearSelection,
} = RedactionRequestListSlice.actions;
export default RedactionRequestListSlice.reducer;
