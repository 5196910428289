import React, { useContext } from "react";
import styled, { css } from "styled-components";

import { AnalysisContext } from "context/AnalysisContext";

import { formatSearchTerms } from "utils/comments";
import { Color } from "ts/enums/color";
import { Comment as CommentType } from "ts/comments";
import { CommentsSearchType, RedactionDisplayMethod } from "@explorance/mly-types";
import { Text } from "components/Text";

type Props = {
  comment: CommentType;
  searchField: string;
};

export const CommentText = ({ comment, searchField }: Props) => {
  const [state] = useContext(AnalysisContext);

  const searchRegExp = () => {
    if (searchField && searchField.length > 0) {
      const formattedSearch = formatSearchTerms(searchField);
      return new RegExp(`(${formattedSearch})`, "gi");
    }
  };

  const renderCommentParts = () => {
    const parts =
      state.selectedTranslationLanguage &&
      comment?.translations &&
      Object.keys(comment.translations).includes(state.selectedTranslationLanguage)
        ? comment.translations[state.selectedTranslationLanguage].split(searchRegExp())
        : comment.value.split(searchRegExp());
    return parts.map((part, i) => (
      <span key={i} className={searchRegExp().test(part) ? "highlight" : undefined}>
        {part}
      </span>
    ));
  };

  if (!comment.value) return null;

  return (
    <StyledCommentText isRedacted={!!comment.redactionDisplayMethod}>
      {!!comment.redactionDisplayMethod && (
        <StyledCommentRedactedCaption>
          <Text resource="comments.redacted.display" />
        </StyledCommentRedactedCaption>
      )}
      {comment.redactionDisplayMethod !== RedactionDisplayMethod.Total &&
        (searchField && state.commentsSearchType === CommentsSearchType.Comments
          ? renderCommentParts()
          : state.selectedTranslationLanguage &&
            comment.translations?.[state.selectedTranslationLanguage]
          ? comment.translations[state.selectedTranslationLanguage]
          : comment.value)}
    </StyledCommentText>
  );
};

const StyledCommentText = styled.p<{ isRedacted?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${Color.gray50};
  margin: 0;
  white-space: break-spaces;

  ${({ isRedacted }) =>
    isRedacted &&
    css`
      border-left: 2px solid ${Color.indigo20};
      padding-left: 24px;
    `};

  .highlight {
    background: ${Color.blue40};
    color: ${Color.white};
    // keep this value here to prevent highlight of extra characters in Chrome and Edge (to see, comment out this value and search for "of")
    font-weight: 500;
  }
`;

const StyledCommentRedactedCaption = styled.div`
  font-weight: bold;
  font-style: italic;
  margin-bottom: 4px;
`;
