import React from "react";
import styled from "styled-components";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { Checkbox } from "../Checkbox";
import { Color } from "ts/enums/color";
import { TextType } from "ts/TextType";

type CustomStyles = {
  labelFontSize?: string;
  marginLeft?: string;
  position?: string;
};

type Props = {
  checked: boolean;
  label: TextType;
  variant?: CheckboxCheckedAppearance;
  position?: string;
  disabled?: boolean;
  onChange: (e: any) => void;
  customStyles?: CustomStyles;
};

export const CheckboxField = ({
  checked,
  label,
  variant = CheckboxCheckedAppearance.Default,
  onChange,
  customStyles,
  disabled,
  position,
}: Props) => {
  const onLabelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    !disabled && onChange(e);
  };

  return (
    <StyledCheckboxField
      marginLeft={customStyles?.marginLeft}
      disabled={disabled}
      onClick={onLabelClick}
    >
      <Checkbox
        checkedAppearance={variant}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <StyledCheckboxLabel
        disabled={disabled}
        labelFontSize={customStyles?.labelFontSize}
        position={position}
      >
        {label}
      </StyledCheckboxLabel>
    </StyledCheckboxField>
  );
};

const StyledCheckboxField = styled.div<{ marginLeft?: string; disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  user-select: none;
  margin-left: ${({ marginLeft }) => marginLeft ?? "0px"};
  width: fit-content;
  ${({ disabled }) =>
    !disabled &&
    `&:hover {
    .checkbox .overlay {
      border: 1px solid ${Color.blue40};
    }
  }`}
`;

const StyledCheckboxLabel = styled.span<{
  labelFontSize?: string;
  disabled?: boolean;
  position?: string;
}>`
  margin-left: 6px;
  margin-bottom: 6px;
  font-size: ${({ labelFontSize }) => labelFontSize ?? "0.875em"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ disabled }) => (disabled ? Color.gray30 : Color.gray50)};
  position: ${({ position }) => position ?? "relative"};
  top: 1px;
`;
