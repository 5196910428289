import React, { useContext } from "react";
import styled from "styled-components";

import { Skeleton } from "components/Skeleton";
import { Color } from "ts/enums/color";
import { ManualRedactionPermission, RoleType } from "@explorance/mly-types";
import { AnalysisContext } from "context/AnalysisContext";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { useAppSelector } from "store";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";
import { useQueryParams } from "hooks/useQueryParams";

export const CommentListPlaceholder = () => {
  const [state] = useContext(AnalysisContext);

  const { currentUser } = useAppSelector((state) => state.auth);
  const { sharingPreview } = useQueryParams();

  const showPinButton =
    (isAnyAdmin(currentUser.roleType) && !sharingPreview) ||
    (currentUser.roleType === RoleType.Analyst &&
      !state.analysisDetails?.sharing &&
      !sharingPreview) ||
    isAnyAdmin(state.previewUser.roleType) ||
    state.previewUser?.allowPin ||
    (state.analysisDetails?.sharing?.allowPin && !sharingPreview);

  const showRedactionButton =
    (isAnyAdmin(currentUser.roleType) && !sharingPreview) ||
    isAnyAdmin(state.previewUser.roleType) ||
    state.previewUser?.manualRedactionPermission !== ManualRedactionPermission.Denied ||
    (state.analysisDetails?.sharing?.manualRedactionPermission !==
      ManualRedactionPermission.Denied &&
      !sharingPreview);

  return (
    <>
      {[...Array(8)].map((_, i) => (
        <StyledCommentPlaceholder className="pulse" key={i}>
          <div>
            <Skeleton width={800} height={16} responsive={true} />
            <Skeleton width={600} height={16} responsive={true} />
          </div>
          <StyledRightSquare>
            {showPinButton && <Skeleton width={24} height={24} backgroundColor={Color.sky50} />}

            {showRedactionButton && IsFeatureActive(Feature.Redaction) && (
              <Skeleton width={24} height={24} backgroundColor={Color.sky50} />
            )}
            <Skeleton width={24} height={24} backgroundColor={Color.sky50} />
            <Skeleton width={24} height={24} backgroundColor={Color.sky50} />
            <Skeleton width={24} height={24} backgroundColor={Color.sky50} />
          </StyledRightSquare>
        </StyledCommentPlaceholder>
      ))}
    </>
  );
};

const StyledCommentPlaceholder = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  border-bottom: 1px solid ${Color.sky20};
  padding: 10px 5px;
  div {
    border-radius: 4px;
  }
`;

const StyledRightSquare = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: 6px;
    border-radius: 4px;
  }
`;
