import React, { useEffect, useContext, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { routes } from "routes";
import { runAnalysis } from "services/analysis";
import { findPrecisionLevel } from "utils/precision";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { isDegraded } from "utils/isDegraded";
import { isSettingsPageUnavailable } from "utils/isSettingsPageUnavailable";
import {
  clearState,
  setShowOverwriteModal,
  setAnalysisDetails,
  initializeSettings,
} from "store/analysisSettings/analysisSettingsSlice";
import { fetchUsedModels } from "store/analysisSettings/thunks";
import { showToastError } from "store/toast/toastSlice";

import { AnalysisSettingsFields } from "./_layouts/AnalysisSettingsFields";
import { AnalysisJobProcessingModal } from "components/_modals/JobProcessingModal";
import { JobFailedModal } from "./_layouts/JobFailedModal";
import { Header } from "./_layouts/Header";
import { formatCommentListForBackEnd } from "./_layouts/AnalysisSettingsFields/PreFiltering/helpers";
import { Button } from "components/_buttons/Button";
import { Notch } from "common-layouts/AnalysisLayout/Notch";
import { OverwriteAnalysisConfirmationModal } from "components/_modals/OverwriteAnalysisConfirmationModal";
import { ErrorScreen } from "components/ErrorScreen";
import { Text } from "components/Text";
import { LoadingDots } from "components/LoadingDots";

import { Color } from "ts/enums/color";
import {
  AnalysisStatus,
  ErrorType,
  FileUploadStatus,
  LanguageCode,
  PermissionLevel,
  RoleType,
} from "@explorance/mly-types";
import { AnalysisJobInput } from "ts/analysis";
import { ButtonVariant, ButtonSize } from "ts/enums/button";
import { PageErrorType } from "ts/enums/pageErrorType";
import {
  resetDataSource,
  setImportModalAnalysisId,
  setImportModalErrorFallbackAnalysisId,
  setImportModalFileId,
  setImportModalOpen,
} from "store/analysisSettings/dataSourceSlice";
import { DataSourceModal } from "common-layouts/DataSourceModal";
import { DataSourceModalType } from "ts/enums/dataSourceModalType";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { deleteImportedFileById } from "services/files";

export const SettingsPage = () => {
  // redux
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.analysisSettings);
  const dataSourceState = useAppSelector((state) => state.dataSource);

  // local state
  const [analysisState, , handlers] = useContext(AnalysisContext);
  const [prevAnalysisFileUploadStatus, setPrevAnalysisFileUploadStatus] =
    useState<FileUploadStatus>(null);
  const { analysisId } = useParams<{ analysisId: string }>();

  const history = useHistory();

  const isModelOutdated =
    (!analysisState.analysisDetails.selectedModel &&
      !!analysisState.analysisDetails.suggestedModel) ||
    analysisState.analysisDetails.selectedModel?.isOutdated ||
    (!analysisState.analysisDetails.selectedModel?.graphId &&
      analysisState.analysisDetails.status === AnalysisStatus.Completed);

  const isAdmin = isAnyAdmin(currentUser.roleType);
  const isSharingViewer =
    analysisState.analysisDetails?.sharing?.permissionLevel === PermissionLevel.View;
  const isRoleViewer = currentUser.roleType === RoleType.Viewer;
  const precisionLevel = findPrecisionLevel(state.selectedPrecision);
  const isAnalyzedButtonDisabled =
    state.selectedColumns.length === 0 ||
    isDegraded(currentUser, analysisState) ||
    isRoleViewer ||
    isSettingsPageUnavailable(analysisState) ||
    state.selectedModel?.isOutdated ||
    state.selectedCustomModel?.isOutdated ||
    !state.selectedModel;

  const handleClickBackToPrevious = () =>
    state.analysisDetails.status === AnalysisStatus.NotAnalyzed ||
    state.analysisDetails.status === AnalysisStatus.Failed
      ? history.push(routes.homePage)
      : history.push(routes.overviewPage(parseInt(analysisId)));

  const dataSourceModalType = dataSourceState.isImportMoreData
    ? DataSourceModalType.ImportFile
    : analysisState.analysisDetails.uploadStatus === FileUploadStatus.Completed
    ? DataSourceModalType.ViewData
    : DataSourceModalType.ImportFile;

  const startJob = async () => {
    try {
      const jobInput: AnalysisJobInput = {
        selectedColumns: state.selectedColumns,
        graphId: state.selectedModel?.graphId || null,
        customModelId: state.selectedCustomModel?.customModelId || null,
        env: state.selectedEnv,
        ...(precisionLevel && { precision: precisionLevel }),
        commenterColumn: state.selectedCommenterColumn,
        variableMappingId: state.selectedVariableMapping,
        excludedValues: formatCommentListForBackEnd(state.excludedCommentList),
        ...{ translationLanguages: [LanguageCode.Eng] },
      };
      await runAnalysis(parseInt(analysisId), jobInput);
      history.push(routes.homePage);
    } catch (err: any) {
      console.error(err);
      if (err.response?.status === 423) {
        dispatch(
          showToastError({
            message: {
              titleKey: "toast.analyzedCommentsQuota1",
              captionKey: "toast.analyzedCommentsQuota2",
            },
          })
        );
      } else if (err.response?.data.details === ErrorType.ModelOutdated) {
        dispatch(showToastError("toast.modelOutdated"));
      } else {
        dispatch(showToastError("toast.defaultError"));
      }
    }
  };

  const handleDiscardFile = async () => {
    await deleteImportedFileById(analysisState.analysisDetails.lastUploadedFileId);
    await handlers.fetchAndSetAnalysisDetails().then(() => {
      if (analysisState.analysisDetails.allColumns.length === 0) {
        history.push(routes.homePage);
      }
    });
  };

  const handleClickAnalyze = () => {
    if (analysisState.analysisDetails.status === AnalysisStatus.Completed) {
      return dispatch(setShowOverwriteModal(true));
    }
    startJob();
  };

  const handleClickEditDataTypes = () => {
    analysisState.analysisDetails.uploadStatus === FileUploadStatus.Completed
      ? dispatch(setImportModalAnalysisId(analysisState.analysisDetails.id))
      : dispatch(setImportModalFileId(analysisState.analysisDetails.lastUploadedFileId));
    dispatch(setImportModalOpen(true));
    dispatch(setImportModalErrorFallbackAnalysisId(analysisState.analysisDetails.id));
  };

  const handleOverwriteAnalysis = () => {
    dispatch(setShowOverwriteModal(false));
    startJob();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      state.selectedModel ||
      analysisState.loadingAnalysisModels ||
      analysisState.loadingAnalysisDetails ||
      !analysisState.analysisDetails
    ) {
      return;
    }

    const details = analysisState.analysisDetails;
    dispatch(setAnalysisDetails(details));

    dispatch(initializeSettings({ isModelOutdated, details }));
    dispatch(fetchUsedModels(analysisId));

    return () => {
      dispatch(clearState());
    };
  }, [
    analysisState.loadingAnalysisDetails,
    analysisState.loadingAnalysisModels,
    analysisId,
    dispatch,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    if (
      prevAnalysisFileUploadStatus === FileUploadStatus.Finalizing &&
      analysisState.analysisDetails.uploadStatus === FileUploadStatus.Completed
    ) {
      dispatch(resetDataSource());
      dispatch(setAnalysisDetails(analysisState.analysisDetails));
      dispatch(initializeSettings({ isModelOutdated, details: analysisState.analysisDetails }));
    }
    setPrevAnalysisFileUploadStatus(analysisState.analysisDetails.uploadStatus);
  }, [analysisState.analysisDetails.uploadStatus, prevAnalysisFileUploadStatus, dispatch]); //eslint-disable-line

  if ((isRoleViewer && analysisState.analysisDetails.sharing) || (!isAdmin && isSharingViewer)) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {analysisState.analysisError &&
      analysisState.analysisError !== PageErrorType.AnalysisNotAnalyzed ? (
        <ErrorScreen errorType={analysisState.analysisError} />
      ) : analysisState.loadingAnalysisDetails ? (
        <StyledLoadingDotsContainer>
          <LoadingDots />
        </StyledLoadingDotsContainer>
      ) : (
        <StyledSettingsPage className="fade-in">
          <StyledNotchContainer>
            <Notch />
          </StyledNotchContainer>
          <AnalysisJobProcessingModal />
          <JobFailedModal />
          <OverwriteAnalysisConfirmationModal
            isOpen={state.showOverwriteModal}
            onCancel={() => dispatch(setShowOverwriteModal(false))}
            onConfirm={handleOverwriteAnalysis}
          />
          <DataSourceModal
            fileUploadStatus={analysisState.analysisDetails.uploadStatus}
            modalType={dataSourceModalType}
            importSuccessCallback={() => dispatch(resetDataSource())}
          />
          <StyledSettingsContainer>
            <Header
              titleKey="analysisSettings.title"
              descriptionKey="analysisSettings.description"
              handleClickBackToPrevious={handleClickBackToPrevious}
              handleDiscardFile={handleDiscardFile}
            />
            <StyledSettingsBodyContainer>
              <AnalysisSettingsFields isModelOutdated={isModelOutdated} />
            </StyledSettingsBodyContainer>
          </StyledSettingsContainer>
          <StyledButtonSectionContainer>
            <Button
              variant={ButtonVariant.outline}
              size={ButtonSize.ml}
              onClick={() => history.goBack()}
            >
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.outlineBlue}
              size={ButtonSize.ml}
              onClick={handleClickEditDataTypes}
            >
              <Text resource="button.editDataTypes" />
            </Button>
            <Button
              variant={ButtonVariant.primary}
              size={ButtonSize.ml}
              onClick={handleClickAnalyze}
              disabled={isAnalyzedButtonDisabled}
            >
              <Text resource="button.analyze" />
            </Button>
          </StyledButtonSectionContainer>
        </StyledSettingsPage>
      )}
    </>
  );
};

const StyledSettingsPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledSettingsBodyContainer = styled.div`
  border: 1px solid ${Color.sky50};
  width: 100%;
  padding: 16px;
  height: fit-content;
  border-radius: 2px;
`;

const StyledSettingsContainer = styled.div`
  padding-bottom: 16px;
`;

const StyledLoadingDotsContainer = styled.div`
  margin-top: 25%;
  margin-left: 50%;
`;

const StyledNotchContainer = styled.div`
  position: absolute;
  left: 50%;
  z-index: ${ZIndexStackingContext.medium};
`;

const StyledButtonSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  gap: 10px;
  margin-bottom: 50px;
`;
