import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Feature } from "ts/enums/feature";

type SettingsState = {
  features: { [key in Feature]: boolean };
};

const initialState: SettingsState = {
  features: {
    redaction: false,
    preferredLanguage: false,
    multilingualAnalysis: false,
    precision: false,
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<{ [key in Feature]: boolean }>) => {
      state.features = action.payload;
    },
  },
});

export const { setFeatures } = settingsSlice.actions;

export default settingsSlice.reducer;
