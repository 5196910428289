import React from "react";
import { useAppDispatch, useAppSelector } from "store";
import { saveRedactionSet } from "store/redaction/thunks";
import {
  setBlockRouteChange,
  setHasErrors,
  setIsValidToSave,
} from "store/redaction/redactionBuilderSlice";
import { RedactionBuilder } from "common-layouts/RedactionBuilder";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";
import { ErrorScreen } from "components/ErrorScreen";
import { PageErrorType } from "ts/enums/pageErrorType";
import { RoleType } from "@explorance/mly-types";

export const NewRedactionPage = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const { currentUser } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleCreateRedaction = async (next) => {
    if (state.isLoading) return;
    if (state.inputtedRedactionList.length === 0) {
      dispatch(setHasErrors(true));
      dispatch(setIsValidToSave(false));
      return;
    }
    await dispatch(saveRedactionSet());
    dispatch(setBlockRouteChange(false));
    next();
  };

  if (!IsFeatureActive(Feature.Redaction)) {
    return <ErrorScreen errorType={PageErrorType.RedactionFeatureNotActivated} />;
  } else if (currentUser.roleType === RoleType.Viewer) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return <RedactionBuilder handleSave={handleCreateRedaction} />;
};
