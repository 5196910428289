import { useEffect } from "react";

const useActionOnScroll = (func: () => void, enabled = true) => {
  useEffect(() => {
    if (!enabled) return;
    document.addEventListener("scroll", enabled && func, true);
    return () => {
      document.removeEventListener("scroll", enabled && func, true);
    };
  }, []); //eslint-disable-line
};

export default useActionOnScroll;
