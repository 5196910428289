import { SocketEvent } from "@explorance/mly-types";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setValidationProgressPercentage } from "store/analysisSettings/dataSourceSlice";

export const useDataSourceValidationProgressBar = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.dataSource);

  const socket = useAppSelector((state) => state.wsStream.socket);
  const [validationBuffer, setValidationBuffer] = useState<number>(0);
  const [validationProgress, setValidationProgress] = useState<number>(0);

  useEffect(() => {
    if (state.validationProgressPercentage > validationProgress && validationBuffer > 0) {
      const timeoutId = setTimeout(() => {
        setValidationProgress((prev) => prev + validationBuffer);
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [validationBuffer, validationProgress]); //eslint-disable-line

  useEffect(() => {
    if (!socket) return;

    socket.on(SocketEvent.ChangedImportProgressBar, (data) => {
      if (state.importModalErrorFallbackAnalysisId !== data.analysisId) {
        return;
      }
      dispatch(setValidationProgressPercentage(data.percentage));
      setValidationBuffer((data.percentage - validationProgress) / 10);
    });

    return () => {
      socket.off(SocketEvent.ChangedImportProgressBar, (data) =>
        dispatch(setValidationProgressPercentage(data.percentage))
      );
    };
  }, [dispatch, socket]); //eslint-disable-line

  return { validationProgress };
};
