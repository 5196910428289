import { Precision } from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppState } from "store/appReducer";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { deleteAnalysis, getAnalysisList } from "services/analysis";
import { AnalysisJobSummary } from "ts/analysis";
import { formatSearchForFetchRequest } from "utils/formatters";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { setEmptyStateType } from "./analysisListSlice";
import { getQuickAnalysisModels, runQuickAnalysis } from "services/quickAnalysis";
import { ModelSelectionOptions } from "ts/models";
import { IsFeatureActive } from "utils/isFeatureActive";
import { precisionSelectionRange } from "assets/constants/precisionSelection";
import { Feature } from "ts/enums/feature";
import { QuickAnalysisApiData } from "ts/comments";
import {
  setIsDisabled,
  setQuickAnalysisFailedStatus,
  setQuickAnalysisState,
} from "./quickAnalysisSlice";
import { QuickAnalysisState } from "ts/enums/quickAnalysis";
import { closeToast } from "store/toast/toastSlice";

export const getAnalyses = createAsyncThunk<AnalysisJobSummary, void, { state: AppState }>(
  "analysis/getAnalysesList",
  async (_, { getState, dispatch }): Promise<AnalysisJobSummary> => {
    const state = getState();
    const searchTerm = state.search.searchTerm;

    const { data: analysisList } = await getAnalysisList({
      view: state.analysisList.listView,
      limit: DEFAULT_LIST_LIMIT,
      page: state.analysisList.currentPage,
      sort_column: state.analysisList.sortColumn,
      sort_order: state.analysisList.sortOrder,
      ...(state.search.searchTerm && {
        search: formatSearchForFetchRequest(state.search.searchTerm.trim()),
      }),
    });

    const emptyStateType =
      analysisList?.totalCount === 0 && searchTerm?.length === 0
        ? EmptyStateType.noListResults
        : analysisList?.totalCount === 0 && searchTerm.length > 0
        ? EmptyStateType.noSearchResults
        : null;

    dispatch(setEmptyStateType(emptyStateType));

    if (!analysisList) {
      throw new Error("Failed to fetch analysis list");
    }

    return analysisList;
  }
);

export const deleteSelectedAnalysis = createAsyncThunk<void, void, { state: AppState }>(
  "analysis/deleteSelectedAnalysis",
  async (_, { getState, dispatch }): Promise<void> => {
    const state = getState();
    await deleteAnalysis(state.analysisList.currentEditedRow.analysisId);
    await dispatch(getAnalyses());
  }
);

export const fetchQuickAnalysisModels = createAsyncThunk<ModelSelectionOptions, void>(
  "quickAnalysis/fetchQuickAnalysisModels",
  async (): Promise<ModelSelectionOptions> => {
    const { data: models } = await getQuickAnalysisModels();
    if (!models) {
      throw new Error("Failed to fetch models");
    }
    return models;
  }
);

export const executeQuickAnalysis = createAsyncThunk<
  QuickAnalysisApiData,
  void,
  { state: AppState }
>(
  "quickAnalysis/executeQuickAnalysis",
  async (_, { getState, dispatch }): Promise<QuickAnalysisApiData> => {
    const state = getState();
    dispatch(closeToast());
    const { data: quickAnalysisApiData } = await runQuickAnalysis({
      comment: state.quickAnalysis.comment,
      ...(state.quickAnalysis.selectedModel?.categorizationType && {
        graphId: state.quickAnalysis.selectedModel?.graphId,
      }),
      env: state.quickAnalysis.selectedEnv,
      precision: IsFeatureActive(Feature.Precision)
        ? precisionSelectionRange.find((p) => p.value === state.quickAnalysis.selectedPrecision)
            ?.bodyValue
        : Precision.high,
    }).then((res) => {
      if (res.status > 400) {
        dispatch(setQuickAnalysisState(QuickAnalysisState.edit));
        dispatch(setIsDisabled(false));
        dispatch(setQuickAnalysisFailedStatus(res.status));
        if (res.status === 429) {
          throw new Error("Too many requests, please try again later.");
        } else {
          throw new Error("Failed to run quick analysis");
        }
      }
      return res;
    });

    return quickAnalysisApiData;
  }
);
