import React, { useRef, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { QuickAnalysisState } from "ts/enums/quickAnalysis";

import { useResource } from "hooks/useResource";

import { Button } from "components/_buttons/Button";
import { LoadingDots } from "components/LoadingDots";
import { AnalysisInterpretation } from "./AnalysisInterpretation";
import { ModelSelection } from "../ModelSelection";

import { PrecisionSelection } from "./PrecisionSelection";
import { FeatureFlag } from "components/FeatureFlag";
import { Feature } from "ts/enums/feature";
import { Select } from "components/_inputs/Select";
import {
  setComment,
  setSelectedEnv,
  setSelectedModel,
  setSelectedPrecision,
  setShowCharacterCount,
  handleGenerateComment,
  clearAll,
  clearState,
  setQuickAnalysisFailedStatus,
} from "store/analysis/quickAnalysisSlice";
import { executeQuickAnalysis, fetchQuickAnalysisModels } from "store/analysis/thunks";
import { CustomToastContentType } from "ts/enums/customToastContentType";
import { showToastError } from "store/toast/toastSlice";
import { Text } from "components/Text";
import { Skeleton } from "components/Skeleton";

const COMMENT_CHAR_LIMIT = 1000;

export const QuickAnalysis = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.quickAnalysis);

  const { getResource } = useResource();

  const commentInputRef = useRef<HTMLTextAreaElement>();
  const inputContainerRef = useRef<HTMLDivElement>();

  const handleClearAll = () => {
    dispatch(clearAll());
    commentInputRef.current.style.height = "185px";
    inputContainerRef.current.style.height = "185px";
  };

  const updateCommentInputHeight = (height: number) => {
    commentInputRef.current.style.height = `${height}px`;
    inputContainerRef.current.style.height = `${height}px`;
    inputContainerRef.current.style.transition = "height 300ms ease-out";
  };

  const handleTextAreaClick = () => {
    if (
      state.quickAnalysisState === QuickAnalysisState.results &&
      inputContainerRef.current.scrollHeight < 183
    ) {
      commentInputRef.current.style.height = "185px";
      inputContainerRef.current.style.height = "185px";
    }
    dispatch(setShowCharacterCount(true));
  };

  const handleRunAnalysis = async () => {
    commentInputRef.current.style.height = "0px";
    const commentHeight = commentInputRef.current.scrollHeight + 4;
    commentInputRef.current.style.height = `${commentHeight}px`;

    await dispatch(executeQuickAnalysis());
    updateCommentInputHeight(commentHeight);
  };

  useEffect(() => {
    dispatch(fetchQuickAnalysisModels());

    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!state.quickAnalysisFailedStatus) return;
    const errorMessage =
      state.quickAnalysisFailedStatus === 429
        ? {
            message: {
              titleKey: "quickAnalysis.error.tooManyRequests.title",
              customToastContentType: CustomToastContentType.quickAnalysisRetry,
            },
          }
        : "toast.defaultError";

    dispatch(showToastError(errorMessage));
    dispatch(setQuickAnalysisFailedStatus(null));
  }, [state.quickAnalysisFailedStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledQuickAnalysis>
      <StyledLoadingContainer
        show={state.quickAnalysisState === QuickAnalysisState.loading}
        className={state.quickAnalysisState === QuickAnalysisState.loading ? "fade-in" : "fade-out"}
      >
        <StyledDotsContainer>
          <LoadingDots />
        </StyledDotsContainer>
      </StyledLoadingContainer>
      <StyledTitle>
        <Text resource="quickAnalysis.title" />
      </StyledTitle>
      <StyledDescription>
        <Text resource="quickAnalysis.description" />
      </StyledDescription>
      <StyledMenuSection>
        <StyledSelectionContainer>
          {state.envDropdownOptions.length > 1 && (
            <StyledAnalysisSetting>
              <StyledSelectionLabel>
                <Text resource="quickAnalysis.env.label" />
              </StyledSelectionLabel>
              {state.models ? (
                <Select
                  options={state.envDropdownOptions.map((env) => ({ label: env, value: env }))}
                  selectedOption={{ label: state.selectedEnv, value: state.selectedEnv }}
                  handleChange={(arg: string) => dispatch(setSelectedEnv(arg))}
                  dropdownWidth="172px"
                  buttonStyle={{
                    width: "92px",
                    marginBottom: "0px",
                    cursor: state.envDropdownOptions.length === 1 ? "default" : "pointer",
                    height: "34px",
                  }}
                  dropdownPosition={{ top: 35 }}
                  buttonVariant={ButtonVariant.neutral}
                  labelStyle={{ fontWeight: "normal", color: Color.gray50 }}
                />
              ) : (
                <Skeleton
                  width={172}
                  height={34}
                  customStyles={{ marginRight: "16px", borderRadius: "5px" }}
                />
              )}
            </StyledAnalysisSetting>
          )}
          <StyledAnalysisSetting>
            <StyledSelectionLabel>
              <Text resource="quickAnalysis.model.label" />:
            </StyledSelectionLabel>
            {state.models ? (
              <ModelSelection
                selectedModel={state.selectedModel}
                options={state.models}
                handleSelectModel={(model) => dispatch(setSelectedModel(model))}
              />
            ) : (
              <Skeleton width={172} height={34} borderRadius="5px" />
            )}
          </StyledAnalysisSetting>
        </StyledSelectionContainer>
        <FeatureFlag feature={Feature.Precision}>
          <StyledSelectionContainer>
            <StyledSelectionLabel>
              <Text resource="quickAnalysis.precision.label" />
            </StyledSelectionLabel>
            <PrecisionSelection
              selectedPrecision={state.selectedPrecision}
              handleSelectPrecision={(value) => dispatch(setSelectedPrecision(value))}
            />
          </StyledSelectionContainer>
        </FeatureFlag>
      </StyledMenuSection>
      {state.models ? (
        <StyledInputContainer ref={inputContainerRef}>
          <StyledCommentInput
            ref={commentInputRef}
            placeholder={getResource("quickAnalysis.comment.placeholder")}
            maxLength={COMMENT_CHAR_LIMIT}
            value={state.comment}
            addMargin={state.showCharacterCount}
            onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch(setComment(e.target.value))
            }
            onFocus={handleTextAreaClick}
            onBlur={() => dispatch(setShowCharacterCount(false))}
          />
          {state.showCharacterCount && (
            <StyledCharacterCount>
              <span>{state.comment.length}</span>
              <Text
                resource={{
                  key: "input.validation.characterCount",
                  args: [`${COMMENT_CHAR_LIMIT}`],
                }}
              />
            </StyledCharacterCount>
          )}
        </StyledInputContainer>
      ) : (
        <Skeleton width={715} height={185} customStyles={{ marginTop: "5px" }} />
      )}
      <AnalysisInterpretation />
      <StyledBottomSection>
        {state.models ? (
          <>
            <Button
              variant={ButtonVariant.light}
              size={ButtonSize.sm}
              style={{ marginRight: "auto" }}
              disabled={state.comment?.length === 0}
              onClick={handleClearAll}
            >
              <Text resource="quickAnalysis.button.clearAll" />
            </Button>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.sm}
              style={{ marginRight: 8 }}
              onClick={() => dispatch(handleGenerateComment())}
            >
              <Text resource="quickAnalysis.button.generateComment" />
            </Button>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.sm}
              disabled={state.isDisabled}
              onClick={handleRunAnalysis}
            >
              <Text resource="quickAnalysis.button.runAnalysis" />
            </Button>
          </>
        ) : (
          <>
            <Skeleton width={50} height={32} backgroundColor={Color.neutral10} />
            <Skeleton
              width={150}
              height={32}
              backgroundColor={Color.neutral10}
              customStyles={{ marginLeft: "auto" }}
            />
            <Skeleton
              width={90}
              height={32}
              backgroundColor={Color.neutral10}
              customStyles={{ marginLeft: 8 }}
            />
          </>
        )}
      </StyledBottomSection>
    </StyledQuickAnalysis>
  );
};

const StyledAnalysisSetting = styled.div`
  display: flex;
  align-items: center;
`;

const StyledQuickAnalysis = styled.div`
  background-color: ${Color.white};
  padding: 24px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 40px;
  @media (min-width: 1366px) {
    width: 765px;
  }
`;

const StyledLoadingContainer = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.45);
  transition: background 500ms ease-out;
  z-index: ${ZIndexStackingContext.low};
`;

const StyledDotsContainer = styled.div`
  background-color: ${Color.white};
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 64px;
  height: 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 1.25em;
  color: ${Color.gray50};
`;

const StyledDescription = styled.div`
  font-size: 0.875em;
  color: ${Color.gray40};
  margin-top: 5px;
`;

const StyledMenuSection = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

const StyledSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1366px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const StyledSelectionLabel = styled.span`
  font-size: 15px;
  margin-right: 6px;
  &:not(:first-child) {
    margin-left: 16px;
  }
  color: ${Color.gray50};
`;

const StyledInputContainer = styled.div`
  height: 185px;
  width: 100%;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledCommentInput = styled.textarea<{ addMargin: boolean }>`
  width: 100%;
  height: 185px;
  border: none;
  padding: 8px 10px;
  resize: none;
  margin-bottom: ${({ addMargin }) => addMargin && "25px"};
  font-family: Lato, sans-serif;

  ::placeholder {
    font-family: Lato, sans-serif;
    color: ${Color.gray30};
  }

  :focus {
    outline: none;
  }
`;

const StyledBottomSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 0.875em;
  position: absolute;
  bottom: 8px;
  left: 8px;

  span {
    font-weight: bold;
  }
`;
