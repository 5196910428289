import { GetResourceFunction } from "hooks/useResource";
import { LicensePeriod, ModelStatistics } from "ts/licensing";
import { SelectOption } from "ts/select";

export const typeLicensePeriodsForDropdown = (
  licensePeriod: LicensePeriod,
  getResource: GetResourceFunction
): SelectOption => {
  const startDate = new Date(licensePeriod.startDate);
  const endDate = new Date(licensePeriod.endDate);

  const dateFormat = new Intl.DateTimeFormat(undefined, {
    month: "long",
    year: "numeric",
  });

  return {
    label: getResource(
      "licensing.periodDropdown.label",
      dateFormat.format(startDate),
      dateFormat.format(endDate)
    ),
    value: licensePeriod.id.toString(),
    data: licensePeriod,
  };
};

export const getModelStatisticsFromData = (
  licenseStat: any,
  getModelName: (model: any) => string
): ModelStatistics => ({
  ...licenseStat,
  analyzedCount: new Intl.NumberFormat().format(licenseStat.analyzedCount),
  name: getModelName(
    licenseStat.modelFamily
      ? {
          categorizationType: licenseStat.modelFamily,
        }
      : null
  ),
});
