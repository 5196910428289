import React, { useRef } from "react";
import styled from "styled-components";

import { useResource } from "hooks/useResource";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { Popup } from "components/Popup";
import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { PopupPosition } from "ts/enums/popupPosition";
import { SelectOption } from "ts/select";
import { ChecklistItem, NestedChecklist } from "components/NestedChecklist";
import { getRandomNumber } from "utils/getRandomNumber";
import { useAppDispatch, useAppSelector } from "store";
import {
  setAnalysisSentiment,
  setFeedbackInputValue,
  setInsightType,
  setSelectedRecommendations,
  setSelectedTopics,
} from "store/feedback/feedbackSlice";
import { FeedbackType, InsightType } from "@explorance/mly-types";
import { sendCommentFeedback } from "store/feedback/thunks";
import { useQueryParams } from "hooks/useQueryParams";

const FEEDBACK_CHAR_LIMIT = 1000;

type Props = {
  anchorEl: null | HTMLElement;
  setIsPopupOpen: (boolean) => void;
};

export const FeedbackPopup = ({ anchorEl, setIsPopupOpen }: Props) => {
  const { getResource } = useResource();
  const dispatch = useAppDispatch();
  const { sharingPreview } = useQueryParams();

  const state = useAppSelector((state) => state.feedbackPopup);

  const inputContainerRef = useRef<HTMLDivElement>();
  const commentTopics = Object.entries(state.comment.categorizedTopics).flatMap(([, values]) =>
    values.map((val) => val)
  );
  const commentRecommendations = Object.entries(state.comment.categorizedRecommendations).flatMap(
    ([, values]) => values.map((val) => val)
  );

  const updateSelectedTopics = (feedbackValue: ChecklistItem) => {
    const value = feedbackValue.values[0];
    const isAllSelected = feedbackValue.values.length > 1;
    const allTopicsSelected = state.selectedTopics.length === commentTopics.length;
    const newSelectedTopics = [...state.selectedTopics];

    if (isAllSelected) {
      dispatch(setSelectedTopics(allTopicsSelected ? [] : commentTopics));
    } else {
      if (newSelectedTopics.includes(value)) {
        newSelectedTopics.splice(newSelectedTopics.indexOf(value), 1);
      } else {
        newSelectedTopics.push(value);
      }
      dispatch(setSelectedTopics(newSelectedTopics));
    }
  };

  const updateSelectedRecommendations = (feedbackValue: ChecklistItem) => {
    const value = feedbackValue.values[0];
    const isAllSelected = feedbackValue.values.length > 1;
    const allRecommendationsSelected =
      state.selectedRecommendations.length === commentRecommendations.length;
    const newSelectedRecommendations = [...state.selectedRecommendations];

    if (isAllSelected) {
      dispatch(
        setSelectedRecommendations(allRecommendationsSelected ? [] : commentRecommendations)
      );
    } else {
      if (newSelectedRecommendations.includes(value)) {
        newSelectedRecommendations.splice(newSelectedRecommendations.indexOf(value), 1);
      } else {
        newSelectedRecommendations.push(value);
      }
      dispatch(setSelectedRecommendations(newSelectedRecommendations));
    }
  };

  const getFeedbackOptions = () => {
    return Object.values(InsightType)
      .map((val) => ({
        label: <Text resource={`comments.feedback.select.${val}`} />,
        value: val,
      }))
      .filter(
        (cat) =>
          // Filter out 'topicSentiments' if commentTopics is empty
          (cat.value !== InsightType.TopicPolarity || commentTopics.length > 0) &&
          // Filter out 'recommendation' if comment.hasRecommendation is false
          (cat.value !== InsightType.TopicRecommendation || state.comment.hasRecommendation) &&
          cat.value !== InsightType.Other &&
          cat.value !== InsightType.Recommendation
      ) as SelectOption[];
  };

  const handleSendFeedback = async () => {
    await dispatch(sendCommentFeedback());
    setIsPopupOpen(false);
  };

  return (
    <Popup
      popupStyles={{ width: 452, padding: 5, maxHeight: 520 }}
      placement={PopupPosition.bottomEnd}
      anchorEl={anchorEl}
      isOpen
      fixedPopup
    >
      <StyledPopupHeader>
        <StyledCommentDetailsTitle>
          <Text resource="comments.feedback.header" />
        </StyledCommentDetailsTitle>
        <StyledCommentDetailsBody>{state.comment.value}</StyledCommentDetailsBody>
      </StyledPopupHeader>
      <StyledPopupBody>
        <StyledBodyHeader>
          <Text resource="comments.feedback.body.description" />
        </StyledBodyHeader>
        <StyledFeedbackSelectionLabel>
          <Text resource="comments.feedback.body.type.label" />
        </StyledFeedbackSelectionLabel>
        <Select
          selectedOption={{
            label: <Text resource={`comments.feedback.select.${state.insightType}`} />,
            value: state.insightType,
          }}
          options={getFeedbackOptions()}
          handleChange={(insightType) => dispatch(setInsightType(insightType))}
          dropdownWidth="420px"
          buttonStyle={{ width: "420px", marginTop: "8px" }}
          labelStyle={{ fontWeight: "normal", fontSize: "15px" }}
        />
        <StyledFeedbackSelectionLabel>
          {state.insightType === InsightType.Polarity || state.insightType === InsightType.Alert ? (
            <Text resource={`comments.feedback.select.${state.insightType}`} />
          ) : (
            <Text resource={`comments.feedback.topics.label`} />
          )}
        </StyledFeedbackSelectionLabel>
        {state.insightType === InsightType.TopicPolarity && commentTopics.length > 0 && (
          <StyledTopicsContainer>
            <NestedChecklist
              id={getRandomNumber()}
              checklistItem={{
                name: getResource("analysisSettings.commentColumn.selectAll"),
                values: commentTopics,
              }}
              selectedValues={[
                {
                  name: getResource("analysisSettings.commentColumn.selectAll"),
                  values: state.selectedTopics,
                },
              ]}
              isExpandable={false}
              updateValues={(value) => updateSelectedTopics(value)}
            />
          </StyledTopicsContainer>
        )}
        {state.insightType === InsightType.TopicRecommendation &&
          commentRecommendations.length > 0 && (
            <StyledTopicsContainer>
              <NestedChecklist
                id={getRandomNumber()}
                checklistItem={{
                  name: getResource("analysisSettings.commentColumn.selectAll"),
                  values: commentRecommendations,
                }}
                selectedValues={[
                  {
                    name: getResource("analysisSettings.commentColumn.selectAll"),
                    values: state.selectedRecommendations,
                  },
                ]}
                isExpandable={false}
                updateValues={(value) => updateSelectedRecommendations(value)}
              />
            </StyledTopicsContainer>
          )}
        <StyledAgreeDisagreeSection>
          <Button
            variant={
              state.analysisSentiment == FeedbackType.Confirm
                ? ButtonVariant.primary
                : ButtonVariant.outlineBlue
            }
            onClick={() =>
              dispatch(
                setAnalysisSentiment(
                  state.analysisSentiment !== FeedbackType.Confirm ? FeedbackType.Confirm : null
                )
              )
            }
            size={ButtonSize.sm}
            style={{ gap: "3px" }}
          >
            <Icon type={IconType.thumbUp} color={Color.blue50} size={16} />
            <Text resource="button.agree" />
          </Button>
          <Button
            variant={
              state.analysisSentiment == FeedbackType.Delete
                ? ButtonVariant.primary
                : ButtonVariant.outlineBlue
            }
            onClick={() =>
              dispatch(
                setAnalysisSentiment(
                  state.analysisSentiment !== FeedbackType.Delete ? FeedbackType.Delete : null
                )
              )
            }
            size={ButtonSize.sm}
            style={{ gap: "3px" }}
          >
            <Icon type={IconType.thumbDown} color={Color.blue50} size={16} />
            <Text resource="button.disagree" />
          </Button>
        </StyledAgreeDisagreeSection>
        <StyledCommentInputTitle>
          <Text resource="comments.feedback.comment.title" />
        </StyledCommentInputTitle>
        <StyledInputContainer ref={inputContainerRef}>
          <StyledInputField
            maxLength={FEEDBACK_CHAR_LIMIT}
            value={state.feedbackInputValue}
            onChange={(e) => dispatch(setFeedbackInputValue(e.target.value))}
            placeholder={getResource("comments.detailsPopup.feedbackForm.placeholder")}
          />
          <StyledCharacterCount>
            <span>{state.feedbackInputValue.length}</span>
            <Text
              resource={{
                key: "input.validation.characterCount",
                args: [`${FEEDBACK_CHAR_LIMIT}`],
              }}
            />
          </StyledCharacterCount>
        </StyledInputContainer>
      </StyledPopupBody>
      <StyledPopupActions>
        <Button
          variant={ButtonVariant.light}
          size={ButtonSize.md}
          onClick={() => setIsPopupOpen(false)}
        >
          <Text resource="button.close" />
        </Button>
        <Button
          variant={ButtonVariant.primary}
          size={ButtonSize.md}
          disabled={
            !state.analysisSentiment ||
            (state.insightType === InsightType.TopicPolarity && !state.selectedTopics.length) ||
            !!sharingPreview
          }
          onClick={handleSendFeedback}
          loading={state.isLoading}
        >
          <Text resource="button.feedback" />
        </Button>
      </StyledPopupActions>
    </Popup>
  );
};

const StyledCommentDetailsTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Color.gray30};
`;

const StyledPopupHeader = styled.div`
  background-color: ${Color.neutral10};
  padding: 12px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
`;

const StyledCommentDetailsBody = styled.div`
  font-size: 14px;
  color: ${Color.gray50};
  max-height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledPopupBody = styled.div`
  padding: 8px 11px 8px 11px;
`;

const StyledBodyHeader = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const StyledFeedbackSelectionLabel = styled.div`
  margin-top: 8px;
  color: ${Color.gray30};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`;

const StyledAgreeDisagreeSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 8px;
`;

const StyledInputContainer = styled.div`
  width: 420px;
  height: 120px;
  border: 1px solid ${Color.sky50};
  margin: 8px 0;
  position: relative;
  padding-bottom: 30px;

  :focus-within {
    border: 1px solid ${Color.blue50};
  }
`;

const StyledInputField = styled.textarea`
  width: 100%;
  height: 90%;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  font-size: 0.875em;
  font-family: Lato;
  color: ${Color.gray50};
  margin: 4px 0 20px 0;
  resize: none;
  border: none;

  ::placeholder {
    color: ${Color.gray20};
  }
  :focus {
    outline: none;
  }
`;

const StyledCharacterCount = styled.div`
  color: ${Color.gray40};
  font-size: 0.875em;
  position: absolute;
  left: 12px;
  background-color: ${Color.white};
  width: calc(100% - 24px);
  bottom: 8px;
  span {
    font-weight: bold;
  }
`;

const StyledCommentInputTitle = styled.div`
  color: ${Color.black};
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
`;

const StyledPopupActions = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 18px 12px;
  gap: 12px;
`;

const StyledTopicsContainer = styled.div`
  width: 420px;
  max-height: 160px;
  border: 1px solid ${Color.sky50};
  overflow-y: auto;
  padding: 8px 5px;
  margin-top: 8px;
`;
