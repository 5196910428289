import React, { useContext } from "react";
import styled from "styled-components";

import { PermissionForm } from ".";

import { Color } from "ts/enums/color";
import {
  ManualRedactionPermission,
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { DemographicCustomCondition, RedactionCustomCondition } from "ts/sharing";
import { useAppDispatch, useAppSelector } from "store";
import {
  setPermissionFields,
  setShowCustomConditionError,
  setShowRedactionCustomConditionError,
} from "store/sharing/permissionSlice";
import { saveSharingDraft } from "store/sharing/thunks";
import { AnalysisContext } from "context/AnalysisContext";
import { RadioOption } from "ts/radio";

export const PermissionFormContainer = () => {
  const dispatch = useAppDispatch();
  const [state] = useContext(AnalysisContext);
  const sharing = useAppSelector((state) => state.sharing);
  const permissionsState = useAppSelector((state) => state.permissions);

  const showPermissionFields =
    permissionsState.permissionFields.permissionLevel === PermissionLevel.View;

  // Functions
  const handleSelectPermissionLevel = (permissionLevel: PermissionLevel) => {
    dispatch(setPermissionFields({ ...permissionsState.permissionFields, permissionLevel }));
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleAllowPin = () => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        allowPin: !permissionsState.permissionFields.allowPin,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleSelectDashboardAccess = () => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        dashboardAccess:
          permissionsState.permissionFields.dashboardAccess === SharingDashboardAccess.Hidden
            ? SharingDashboardAccess.Shared
            : SharingDashboardAccess.Hidden,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleSelectTopicExplorerAccess = () => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        topicExplorerAccess:
          permissionsState.permissionFields.topicExplorerAccess ===
          SharingTopicExplorerAccess.Hidden
            ? SharingTopicExplorerAccess.Shared
            : SharingTopicExplorerAccess.Hidden,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleSelectCommentExplorerAccess = () => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        commentExplorerAccess:
          permissionsState.permissionFields.commentExplorerAccess ===
          SharingCommentExplorerAccess.Hidden
            ? SharingCommentExplorerAccess.Shared
            : SharingCommentExplorerAccess.Hidden,
        allowExportData:
          permissionsState.permissionFields.commentExplorerAccess ===
          SharingCommentExplorerAccess.Shared
            ? false
            : permissionsState.permissionFields.allowExportData,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleChangeAllowExportData = () => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        allowExportData: !permissionsState.permissionFields.allowExportData,
        commentExplorerAccess:
          permissionsState.permissionFields.commentExplorerAccess ===
            SharingCommentExplorerAccess.Hidden &&
          !permissionsState.permissionFields.allowExportData
            ? SharingCommentExplorerAccess.Shared
            : permissionsState.permissionFields.commentExplorerAccess,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const handleUpdateVisibleComments = (selectedDemographics: DemographicFilter[]) => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        visibleCommentsByDemographicValues: selectedDemographics,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const updateDemographicCustomConditions = (
    updatedCustomConditions: DemographicCustomCondition[],
    noSave?: boolean
  ) => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        customDemographicConditions: updatedCustomConditions,
      })
    );
    if (!noSave ?? true) dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const updateRedactionCustomConditions = (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        redactionCustomConditions: updatedCustomConditions,
      })
    );
    if (!noSave ?? true) dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const updateManualRedactionPermission = (selectedManualRedactionPermission: RadioOption) => {
    const key = Object.keys(ManualRedactionPermission).find(
      (key) => ManualRedactionPermission[key] === selectedManualRedactionPermission.value
    );

    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        manualRedactionPermission: ManualRedactionPermission[key],
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const updateSelectedRedactionVersion = (selectedRedactionVersionId: string) => {
    const selectedVersion = sharing.redactionVersions.find(
      (version) => `${version.id}` === selectedRedactionVersionId
    );

    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        visibleRedactionVersion: selectedVersion,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const updateSavedDemHeaders = (updatedHeaders: DemographicFilterHeader[]) => {
    dispatch(
      setPermissionFields({
        ...permissionsState.permissionFields,
        visibleDemographicsFilters: updatedHeaders,
      })
    );
    dispatch(saveSharingDraft(state.analysisDetails.id));
  };

  const permissionLevelProps = {
    selectedPermissionLevel: permissionsState.permissionFields.permissionLevel,
    handleSelectPermissionLevel,
  };

  const visibleAnalysisDataProps = {
    selectedDemographics: permissionsState.permissionFields.visibleCommentsByDemographicValues,
    selectedCustomConditions: permissionsState.permissionFields.customDemographicConditions,
    showCustomConditionError: permissionsState.showCustomConditionError,
    userDemographics: sharing.allUserDemographics,
    updateSelectedDemographics: handleUpdateVisibleComments,
    updateDemographicCustomConditions,
    handleHideCustomConditionError: () => dispatch(setShowCustomConditionError(false)),
  };

  const visibleRedactionVersionProps = {
    selectedCustomConditions: permissionsState.permissionFields.redactionCustomConditions,
    userDemographics: sharing.allUserDemographics,
    selectedRedactionVersion: permissionsState.permissionFields.visibleRedactionVersion,
    showRedactionCustomConditionError: permissionsState.showRedactionCustomConditionError,
    selectedManualRedactionPermission: permissionsState.permissionFields.manualRedactionPermission,
    updateManualRedactionPermission,
    updateRedactionCustomConditions,
    updateSelectedRedactionVersion,
    handleHideRedactionCustomConditionError: () =>
      dispatch(setShowRedactionCustomConditionError(false)),
  };

  const visibleDemographicsDataProps = {
    savedDemHeaders: permissionsState.permissionFields.visibleDemographicsFilters,
    updateSavedDemHeaders,
  };

  const dashboardSettingsDataProps = {
    dashboardAccess: permissionsState.permissionFields.dashboardAccess,
    topicExplorerAccess: permissionsState.permissionFields.topicExplorerAccess,
    commentExplorerAccess: permissionsState.permissionFields.commentExplorerAccess,
    allowExportData: permissionsState.permissionFields.allowExportData,
    allowPin: permissionsState.permissionFields.allowPin,
    handleAllowPin,
    handleSelectDashboardAccess,
    handleSelectTopicExplorerAccess,
    handleSelectCommentExplorerAccess,
    handleToggleExportData: handleChangeAllowExportData,
  };

  return (
    <PermissionForm
      showPermissionFields={showPermissionFields}
      analysisDemographicHeaders={sharing.analysisDemographicHeaders}
      // props for each section of the form have the following info: state, handler functions
      permissionLevelData={permissionLevelProps}
      visibleAnalysisData={visibleAnalysisDataProps}
      visibleDemographicsData={visibleDemographicsDataProps}
      visibleRedactionVersionData={visibleRedactionVersionProps}
      dashboardSettingsData={dashboardSettingsDataProps}
    />
  );
};

export const StyledHorizontalField = styled.div<{
  marginBottom?: string;
  editSharing?: boolean;
  hasError?: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || "16px"};
  font-size: 15px;
  width: 100%;

  label {
    font-weight: bold;
    min-width: 120px;
    user-select: none;
    margin-left: ${({ editSharing }) => (editSharing ? "auto" : "0px")};
  }

  input {
    padding: 8px 10px;
    border: ${({ hasError }) => `1px solid ${hasError ? Color.red50 : Color.sky50}`};
    background-color: ${({ hasError }) => (hasError ? `${Color.red10}` : "initial")};
    border-radius: 2px;
  }
`;
