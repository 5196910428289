import React from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { ButtonSize, ButtonVariant } from "ts/enums/button";

import noResultsFound from "assets/images/no-results-found.svg";
import emptyAnalysis from "assets/images/empty-analysis.svg";
import emptyCustomModels from "assets/images/empty-custom-model-side-drawer.svg";
import noSharedUsers from "assets/images/no-shared-users.svg";
import noSharedGroups from "assets/images/no-shared-groups.svg";
import noCommentsFound from "assets/images/no-comments-found.svg";
import noLicensingPeriod from "assets/images/no-licensing-period.svg";
import noCommentColumnsSelected from "assets/images/no-comment-columns-selected.svg";
import noPreFilteringResultsFound from "assets/images/no-prefiltering-results-found.svg";
import noDiscussionThreadNotes from "assets/images/no-discussion-thread-comments.svg";
import noSuggestedRedactionTerms from "assets/images/no-suggested-redaction-terms.svg";
import noSuggestedRedactionTermsFound from "assets/images/no-suggested-redaction-terms-found.svg";

import { Button } from "components/_buttons/Button";
import { Text } from "components/Text";

type CustomStyles = {
  imageWidth?: string;
  imageHeight?: string;
  titleWidth?: number;
  captionWidth?: number;
  display?: string;
  marginTop?: string;
  marginBottom?: string;
  addGrayStyle?: boolean;
};

type NoSearchResults = {
  type: EmptyStateType.noSearchResults;
  customStyles?: CustomStyles;
  handleClickCaptionLink?(): void;
};

type NoListResults = {
  type: EmptyStateType.noListResults;
  image: string;
  titleKey: string;
  captionKey: string;
  secondCaptionKey?: string;
  linkKey?: string;
  customStyles?: CustomStyles;
  CreateButton?: JSX.Element;
  handleClickCaptionLink?(): void;
};

type NoFilterResults = {
  type: EmptyStateType.noFilterResults;
  customStyles?: CustomStyles;
};

type AllTopicsMapped = {
  type: EmptyStateType.allTopicsMapped;
  customStyles?: CustomStyles;
  handleClickCaptionLink(): void;
};

type NoSharedUsers = {
  type: EmptyStateType.noSharedUsers;
  customStyles?: CustomStyles;
  handleClickCaptionLink(): void;
};

type NoSharedGroups = {
  type: EmptyStateType.noSharedGroups;
  customStyles?: CustomStyles;
  handleClickCaptionLink(): void;
};

type NoUsersInGroup = {
  type: EmptyStateType.noUsersInGroup;
  customStyles?: CustomStyles;
};

type NoRelevantComments = {
  type: EmptyStateType.noRelevantComments;
  titleKey: string;
  customStyles?: CustomStyles;
};

type NoLicensingPeriod = {
  type: EmptyStateType.noLicensingPeriod;
  customStyles?: CustomStyles;
};

type NoCommentColumnsSelected = {
  type: EmptyStateType.noCommentColumnsSelected;
  customStyles?: CustomStyles;
};

type NoPreFilteringResultsFound = {
  type: EmptyStateType.noPreFilteringResultsFound;
  customStyles?: CustomStyles;
};

type NoWidgetGroups = {
  type: EmptyStateType.noWidgetGroups;
  customStyles?: CustomStyles;
};

type NoDiscussionThreadNotes = {
  type: EmptyStateType.noDiscussionThreadNotes;
  customStyles?: CustomStyles;
};

type NoSuggestedRedactionTerms = {
  type: EmptyStateType.noSuggestedRedactionTerms;
  customStyles?: CustomStyles;
};

type NoSuggestedRedactionTermsFound = {
  type: EmptyStateType.noSuggestedRedactionTermsFound;
  customStyles?: CustomStyles;
};

type Props =
  | NoSearchResults
  | NoListResults
  | NoFilterResults
  | AllTopicsMapped
  | NoSharedUsers
  | NoSharedGroups
  | NoUsersInGroup
  | NoRelevantComments
  | NoLicensingPeriod
  | NoCommentColumnsSelected
  | NoPreFilteringResultsFound
  | NoWidgetGroups
  | NoDiscussionThreadNotes
  | NoSuggestedRedactionTerms
  | NoSuggestedRedactionTermsFound;

export const EmptyState = (props: Props) => {
  const getEmptyState = () => {
    switch (props.type) {
      case EmptyStateType.noSearchResults:
        return (
          <>
            <img src={noResultsFound} alt="no-search-results-found" />
            <StyledEmptyStateTitle>
              <Text resource="emptyState.noSearchResults.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateBody>
              <StyledEmptyStateCaption>
                <Text resource="emptyState.noSearchResults.caption" />
              </StyledEmptyStateCaption>
              <StyledEmptyStateLink onClick={props.handleClickCaptionLink}>
                <Text resource="emptyState.link.noSearchResults" />
              </StyledEmptyStateLink>
            </StyledEmptyStateBody>
          </>
        );
      case EmptyStateType.noFilterResults:
        return (
          <StyledNoFilterResultsContainer>
            <img src={emptyAnalysis} alt="no-filter-results-found" />
            <div>
              <Text resource="emptyState.analysisOverview.caption" />
            </div>
          </StyledNoFilterResultsContainer>
        );
      case EmptyStateType.noListResults:
        return (
          <>
            <img src={props.image} alt="empty-list" />
            <StyledEmptyStateTitle>
              <Text resource={props.titleKey} />
            </StyledEmptyStateTitle>
            <StyledEmptyStateBody>
              <StyledEmptyStateCaption>
                <Text resource={props.captionKey} />
              </StyledEmptyStateCaption>
              <Text resource={props.secondCaptionKey} />
              <StyledEmptyStateLink onClick={props.handleClickCaptionLink}>
                <Text resource={props.linkKey} />
              </StyledEmptyStateLink>
            </StyledEmptyStateBody>
            <>{props.CreateButton}</>
          </>
        );
      case EmptyStateType.allTopicsMapped:
        return (
          <>
            <img src={emptyCustomModels} alt="all-topics-mapped" />
            <StyledEmptyStateTitle>
              <Text resource="emptyState.customModelSideDrawer.title" />
            </StyledEmptyStateTitle>
            <StyledAllTopicsMappedContainer>
              <div>
                <Text resource="emptyState.customModelSideDrawer.captionLineOne" />
              </div>
              <div>
                <Text resource="emptyState.customModelSideDrawer.captionLineTwo" />
                <span onClick={props.handleClickCaptionLink}>
                  <Text resource="emptyState.link.customModelSideDrawer" />
                </span>
              </div>
            </StyledAllTopicsMappedContainer>
          </>
        );
      case EmptyStateType.noSharedUsers:
      case EmptyStateType.noSharedGroups:
        return (
          <>
            <img
              src={props.type === EmptyStateType.noSharedUsers ? noSharedUsers : noSharedGroups}
              alt={
                props.type === EmptyStateType.noSharedUsers ? "no-shared-users" : "no-shared-groups"
              }
            />
            <StyledEmptyStateTitle>
              <Text
                resource={
                  props.type === EmptyStateType.noSharedUsers
                    ? "emptyState.noSharedUsers.title"
                    : "emptyState.noSharedGroups.title"
                }
              />
            </StyledEmptyStateTitle>
            <StyledEmptyStateBody>
              <StyledEmptyStateCaption>
                <Text resource="emptyState.noSharedUsers.caption" />
              </StyledEmptyStateCaption>
            </StyledEmptyStateBody>
            <Button
              variant={ButtonVariant.secondary}
              size={ButtonSize.sm}
              onClick={props.handleClickCaptionLink}
            >
              <Text resource="emptyState.noSharedUsers.button" />
            </Button>
          </>
        );
      case EmptyStateType.noUsersInGroup:
        return (
          <>
            <img src={noSharedGroups} alt="no-users-left-in-group" />
            <StyledEmptyStateTitle>
              <Text resource="emptyState.noUsersInGroup.title" />
            </StyledEmptyStateTitle>
          </>
        );
      case EmptyStateType.noRelevantComments:
        return (
          <>
            <img src={noCommentsFound} alt="no-comments-found" />
            <StyledEmptyStateTitle>
              <Text resource={props.titleKey} />
            </StyledEmptyStateTitle>
          </>
        );
      case EmptyStateType.noLicensingPeriod:
        return (
          <>
            <img src={noLicensingPeriod} alt="no-licensing-period" />
            <StyledEmptyStateTitle>
              <Text resource="licensing.emptyState.periodNotFound.heading" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption>
              <Text resource="licensing.emptyState.periodNotFound.subHeading" />
            </StyledEmptyStateCaption>
          </>
        );
      case EmptyStateType.noWidgetGroups:
        return (
          <>
            <StyledEmptyStateTitle>
              <Text resource="emptyState.noWidgetGroups.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption>
              <Text resource="emptyState.noWidgetGroups.caption" />
            </StyledEmptyStateCaption>
          </>
        );
      case EmptyStateType.noCommentColumnsSelected:
        return (
          <>
            <img src={noCommentColumnsSelected} alt="no-comment-columns-selected" />
            <StyledEmptyStateTitle style={{ marginTop: "0px" }}>
              <Text resource="emptyState.noCommentColumnsSelected.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption style={{ fontSize: "14px", marginTop: "8px" }}>
              <Text resource="emptyState.noCommentColumnsSelected.caption" />
            </StyledEmptyStateCaption>
          </>
        );
      case EmptyStateType.noPreFilteringResultsFound:
        return (
          <>
            <img src={noPreFilteringResultsFound} alt="no-prefilering-results-found" />
            <StyledEmptyStateTitle style={{ marginTop: "0px" }}>
              <Text resource="emptyState.noPreFilteringResultsFound.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption style={{ fontSize: "14px", marginTop: "8px" }}>
              <Text resource="emptyState.noPreFilteringResultsFound.caption" />
            </StyledEmptyStateCaption>
          </>
        );
      case EmptyStateType.noDiscussionThreadNotes:
        return (
          <>
            <img src={noDiscussionThreadNotes} alt="no-discussion-thread-comments" />
            <StyledEmptyStateTitle style={{ marginTop: "8px" }}>
              <Text resource="emptyState.noDiscussionThreadNotes.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption style={{ fontSize: "14px", marginTop: "8px" }}>
              <Text resource="emptyState.noDiscussionThreadNotes.caption" />
            </StyledEmptyStateCaption>
          </>
        );
      case EmptyStateType.noSuggestedRedactionTerms:
        return (
          <>
            <img src={noSuggestedRedactionTerms} alt="no-suggested-redaction-terms" />
            <StyledEmptyStateTitle style={{ marginTop: "8px" }}>
              <Text resource="emptyState.noRelatedRedactionTerms.title" />
            </StyledEmptyStateTitle>
          </>
        );
      case EmptyStateType.noSuggestedRedactionTermsFound:
        return (
          <>
            <img src={noSuggestedRedactionTermsFound} alt="no-suggested-redaction-terms-found" />
            <StyledEmptyStateTitle style={{ marginTop: "8px" }}>
              <Text resource="emptyState.noRedactionTermsFound.title" />
            </StyledEmptyStateTitle>
            <StyledEmptyStateCaption>
              <Text resource="emptyState.noRedactionTermsFound.caption" />
            </StyledEmptyStateCaption>
          </>
        );
    }
  };
  return <StyledEmptyState customStyles={props.customStyles}>{getEmptyState()}</StyledEmptyState>;
};

type StyledProps = {
  customStyles: CustomStyles;
};

const StyledEmptyStateTitle = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 20px;
  color: ${Color.black};
`;

const StyledEmptyStateBody = styled.div`
  align-items: center;
`;

const StyledEmptyStateCaption = styled.div`
  font-size: 0.875em;
  margin: 12px 0;
  color: ${Color.gray40};
`;

const StyledEmptyStateLink = styled.span`
  color: ${Color.blue50};
  cursor: pointer;
  font-size: 0.9em;
  margin-left: 2px;
`;

const StyledEmptyState = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: ${(props) => props?.customStyles?.marginTop ?? "150px"};
  margin-bottom: ${(props) => props?.customStyles?.marginBottom ?? "0"};
  background-color: ${(props) => props?.customStyles?.addGrayStyle && Color.neutral20};
  padding: ${(props) => props?.customStyles?.addGrayStyle && "100px 25px"};

  img {
    width: ${(props) => props?.customStyles?.imageWidth ?? "145px"};
    height: ${(props) => props?.customStyles?.imageHeight ?? "130px"};
  }

  ${StyledEmptyStateTitle} {
    width: ${(props) => props?.customStyles?.titleWidth};
  }

  ${StyledEmptyStateBody} {
    display: ${(props) => props?.customStyles?.display};
  }

  ${StyledEmptyStateCaption} {
    width: ${(props) => props?.customStyles?.captionWidth};
  }
`;

const StyledNoFilterResultsContainer = styled.div`
  img {
    width: 267px;
    height: 150px;
  }

  div {
    font-size: 0.875em;
    color: ${Color.black};
    font-weight: bold;
    margin-top: 15px;
  }
`;

const StyledAllTopicsMappedContainer = styled.div`
  div {
    font-size: 0.875em;
    color: ${Color.gray40};
  }

  div:first-child {
    margin-top: 12px;
  }

  span {
    color: ${Color.red30};
    cursor: pointer;
    font-size: 1em;
    margin-left: 2px;
    text-decoration: underline;
  }
`;
