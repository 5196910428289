import { Text } from "components/Text";
import { TextTruncator } from "components/TextTruncator";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { Color } from "ts/enums/color";
import { RadioOption } from "ts/radio";

type CustomStyles = {
  labelFontSize?: string;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  itemGap?: number;
  maxHeight?: number;
  radioLabelWidth?: string;
};

type Props = {
  selectedOption?: RadioOption;
  options: RadioOption[];
  onChange: (e: RadioOption | null) => void;
  customStyles?: CustomStyles;
  disabled?: boolean;
  actionSectionContent?: (option) => ReactNode;
  addNoneOption?: boolean;
};

export const RadioGroup = ({
  selectedOption,
  options,
  disabled,
  onChange,
  customStyles,
  actionSectionContent,
  addNoneOption,
}: Props) => {
  return (
    <StyledRadioGroup
      margin={customStyles?.margin}
      padding={customStyles?.padding}
      itemGap={customStyles?.itemGap}
      disabled={disabled}
      maxHeight={customStyles?.maxHeight}
    >
      {addNoneOption && (
        <StyledRadioRow>
          <StyledRadioInputContainer onClick={() => onChange(null)}>
            <StyledRadioInput type="radio" checked={!selectedOption} />
            <StyledRadioLabel>
              <Text resource="radioGroup.none" />
            </StyledRadioLabel>
          </StyledRadioInputContainer>
        </StyledRadioRow>
      )}
      {options.map((option) => (
        <StyledRadioRow key={option.value}>
          <StyledRadioInputContainer onClick={() => !disabled && onChange(option)}>
            <StyledRadioInput
              type="radio"
              checked={!!selectedOption && selectedOption?.value === option.value}
            />
            <StyledRadioLabel>
              <TextTruncator
                value={option.label}
                customWidth={customStyles.radioLabelWidth ?? "260px"}
              />
            </StyledRadioLabel>
          </StyledRadioInputContainer>
          <>{actionSectionContent && actionSectionContent(option)}</>
        </StyledRadioRow>
      ))}
    </StyledRadioGroup>
  );
};

const StyledRadioGroup = styled.div<{
  margin?: string;
  padding?: string;
  disabled?: boolean;
  itemGap: number;
  maxHeight: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow: auto;
  gap: ${({ itemGap }) => itemGap}px;
  width: 100%;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;

const StyledRadioRow = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  input[type="radio"] {
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid ${Color.blue20};
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    background-color: ${Color.white};
  }

  input[type="radio"]:checked::after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: ${Color.blue50};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledRadioLabel = styled.div`
  font-size: 15px;
`;

const StyledRadioInputContainer = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
  min-width: 0px;
`;

const StyledRadioInput = styled.input`
  background: ${Color.white};
`;
