import React, { useEffect } from "react";
import styled from "styled-components";
import { upperFirst } from "lodash";

import { useAppDispatch, useAppSelector } from "store";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import {
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setShowDeleteConfirmation,
  updateRedactionListSorting,
} from "store/redaction/redactionVersionsSlice";
import { setModalOpen, setRedactionVersionId } from "store/redaction/redactionModalSlice";
import { setSearchTerm } from "store/search/searchbarSlice";
import { fetchRedactionVersionsLists } from "store/redaction/thunks";
import { deleteRedactionVersion } from "services/redactions";
import useTableSize from "hooks/useTableSize";
import { showToastError, showToastSuccess } from "store/toast/toastSlice";

import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyState";
import { Button } from "components/_buttons/Button";
import { StyledTablePageHeader, StyledTablePageTitle } from "components/Table/TablePageHeader";
import { ErrorScreen } from "components/ErrorScreen";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { Text } from "components/Text";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";
import { TableInformation } from "components/Table/ClickableTableHeader";
import { RedactionVersionModal } from "components/_modals/RedactionVersionModal";

import emptySvg from "assets/images/redaction-list-empty-state.svg";
import { FlexTableSize, FlexTableType } from "ts/enums/flexTable";
import { Color } from "ts/enums/color";
import { RoleType } from "@explorance/mly-types";
import { PageErrorType } from "ts/enums/pageErrorType";
import { RedactionVersionsListSortingParameter } from "ts/enums/sorting";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";

const REDACTION_VERSIONS_HEADERS: TableInformation<RedactionVersionsListSortingParameter>[] =
  Object.values(RedactionVersionsListSortingParameter).map((vm) => ({
    headerName: vm,
    sortingParameter: RedactionVersionsListSortingParameter[upperFirst(vm)],
  }));

export const RedactionVersionsList = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.redactionVersionsList);
  const modalState = useAppSelector((state) => state.redactionModal);
  const tableSize = useTableSize();

  const dispatch = useAppDispatch();

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    const sortColumn = REDACTION_VERSIONS_HEADERS[columnIndex]
      .headerName as RedactionVersionsListSortingParameter;

    dispatch(updateRedactionListSorting({ sortColumn, sortOrder: order }));
    dispatch(fetchRedactionVersionsLists());
  };

  const deleteRedaction = async () => {
    try {
      await deleteRedactionVersion(state.currentEditedRow.redactionVersionId);
      dispatch(setShowDeleteConfirmation(false));
      dispatch(fetchRedactionVersionsLists());
    } catch (err: any) {
      if (err.response.status === 401) {
        dispatch(setShowDeleteConfirmation(false));
        dispatch(showToastError("toast.redactionVersion.error.delete"));
      }
    } finally {
      dispatch(showToastSuccess("toast.redactionVersionDeleted"));
    }
  };

  const getDotsMenuContents = (rowId: number) => {
    return [
      {
        label: <Text resource="redaction.versions.edit" />,
        onClick: () => {
          dispatch(setRedactionVersionId(rowId));
          dispatch(setModalOpen(true));
        },
      },
      {
        label: <Text resource="button.delete" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
      },
    ];
  };

  const onSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchRedactionVersionsLists());
  };

  const handlePageSelection = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchRedactionVersionsLists());
  };

  useEffect(() => {
    dispatch(fetchRedactionVersionsLists());
    // Reset state when component unmounts
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  if (!IsFeatureActive(Feature.Redaction)) {
    return <ErrorScreen errorType={PageErrorType.RedactionFeatureNotActivated} />;
  } else if (
    currentUser.roleType === RoleType.Viewer ||
    currentUser.roleType === RoleType.Analyst
  ) {
    return <ErrorScreen errorType={PageErrorType.GeneralInsufficientPermission} />;
  }

  return (
    <>
      {state.isLoading ? (
        <ListPageHeaderPlaceholder
          showCreateButton
          showFilterButton={false}
          applyMorePadding
          buttonPlaceholderWidth={200}
          searchInputPlaceholderWidth={tableSize === FlexTableSize.Full ? 400 : 200}
        />
      ) : (
        <div className="fade-in">
          <ActionConfirmationModal
            isOpen={state.showDeleteConfirmation}
            title={
              <Text
                resource={{
                  key: "modal.deleteWithName.title",
                  args: [state.currentEditedRow?.name],
                }}
              />
            }
            caption={<Text resource="modal.deleteVersion.caption" />}
            actionButtonLabel={<Text resource="button.delete" />}
            onClose={() => {
              dispatch(setShowDeleteConfirmation(false));
            }}
            onCancel={() => dispatch(setShowDeleteConfirmation(false))}
            onClickActionButton={deleteRedaction}
          />
          <StyledTablePageHeader>
            <StyledHeader>
              <StyledRedactionListTitle>
                <Text resource="redaction.versions.list.title" />
              </StyledRedactionListTitle>
              <SearchRow
                dataType="searchBar.dataType.versions"
                currentCount={state.currentRedactionVersionsCount}
                totalCount={state.totalRedactionVersionsCount}
                onSearch={onSearch}
                customStyles={{ width: tableSize === FlexTableSize.Full ? "400px" : "300px" }}
              />
              <StyledButtonBox>
                <Button onClick={() => dispatch(setModalOpen(true))}>
                  <Text resource="button.createRedactionVersion" />
                </Button>
              </StyledButtonBox>
            </StyledHeader>
          </StyledTablePageHeader>
          {state.currentRedactionVersionsCount > 0 && (
            <StyledPaginationContainer>
              <Pagination
                currentPage={state.currentPage}
                currentItemsCount={state.currentRedactionVersionsCount}
                handlePageSelection={handlePageSelection}
              />
            </StyledPaginationContainer>
          )}
        </div>
      )}
      {state.totalRedactionVersionsCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: REDACTION_VERSIONS_HEADERS.map((h, i) => (
              <Text key={i} resource={`redaction.versions.table.[${h.headerName}]`} />
            )).concat(<></>),
            rows: state.redactionVersions?.map((redactionVersion) => ({
              contentId: redactionVersion.redactionVersionId,
              data: [
                <StyledRedactionName key={redactionVersion.id}>
                  <TextTruncator
                    value={<b>{redactionVersion.name}</b>}
                    id={redactionVersion.id}
                    key={redactionVersion.id}
                    customWidth="85%"
                  />
                </StyledRedactionName>,
                <span key={`type-${redactionVersion.id}`}>
                  <Text
                    resource={`redaction.versions.includeManual.[${redactionVersion.includeManual}]`}
                  />
                </span>,
                <span key={`last-updated-${redactionVersion.id}`}>
                  {getInternationalDateFormat(
                    getDayMonthYearFromDate(redactionVersion.createdDate)
                  )}
                </span>,
                <span key={`last-updated-${redactionVersion.id}`}>
                  {getInternationalDateFormat(
                    getDayMonthYearFromDate(redactionVersion.lastUpdated)
                  )}
                </span>,
              ],
              dotsMenuParams: {
                rowId: redactionVersion.id,
                currentEditedRowId: state.currentEditedRow?.id,
                menuContents: getDotsMenuContents(redactionVersion.redactionVersionId),
                onDotMenuClick: () => dispatch(setCurrentEditedRow(redactionVersion)),
              },
            })),
            columnWidths: ["30%", "25%", "20%", "20%", "5%"],
            headerMinWidths: [155, 35, 35, 35],
          }}
          handleDoubleClickRow={(id) => {
            dispatch(setRedactionVersionId(id));
            dispatch(setModalOpen(true));
          }}
          customStyles={{
            rows: {
              padding: "10px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: REDACTION_VERSIONS_HEADERS.findIndex(
              (h) => h.headerName === state.sortColumn
            ),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptySvg}
          titleKey="emptyState.noRedactionVersions.title"
          captionKey="emptyState.noRedactionVersions.caption"
          handleClickCaptionLink={() => dispatch(setSearchTerm(""))}
          CreateButton={
            <Button onClick={() => dispatch(setModalOpen(true))}>
              <Text resource="button.createRedactionVersion" />
            </Button>
          }
        />
      )}
      {modalState.isModalOpen && <RedactionVersionModal />}
    </>
  );
};

const StyledPaginationContainer = styled.div`
  padding-bottom: 15px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRedactionListTitle = styled(StyledTablePageTitle)`
  font-size: 1em;
`;

const StyledRedactionName = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 14px;
`;

const StyledButtonBox = styled.div`
  display: flex;
  gap: 15px;
  margin-left: auto;
`;
