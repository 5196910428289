import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "store";
import { AnalysisContext } from "context/AnalysisContext";

import { useResource } from "hooks/useResource";
import { useQueryParams } from "hooks/useQueryParams";
import { updateAnalysis, getCommentersCount } from "services/analysis";
import {
  setAnalysisDetails,
  setCommenterDetails,
  setSelectedModelId,
} from "context/AnalysisContext/actions";
import { getAbbreviatedNumber } from "utils/formatters";
import { getCustomModelId } from "utils/getCustomModelId";
import { isAnyAdmin } from "utils/isAnyAdmin";

import {
  AnalysisSettingsDropdownField,
  StyledSettingsSelectionTitle,
} from "common-layouts/AnalysisSettingsDropdownField";
import { ModelDropdown } from "components/ModelDropdown";
import { TotalBlockCount } from "../TotalBlockCount";
import { Icon, IconType } from "components/_icons/Icon";
import { ExcludedValues } from "../ExcludedValues";
import { Text } from "components/Text";

import { PermissionLevel, RoleType } from "@explorance/mly-types";
import { AnalysisModel } from "ts/filters/analysisModel";
import { Color } from "ts/enums/color";
import { Redactions } from "../Redactions";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";

export const AnalysisSettings = () => {
  const { currentUser } = useAppSelector((state) => state.auth);

  const [state, dispatch] = useContext(AnalysisContext);

  const [localSelectedModel, setLocalSelectedModel] = useState<AnalysisModel | null>();
  const [variableMappingName, setVariableMappingName] = useState<string>();

  const { getResource } = useResource();
  const { sharingPreview } = useQueryParams();
  const customModelId = getCustomModelId(state);
  const isAdmin = isAnyAdmin(currentUser.roleType);
  const countInfo = getAbbreviatedNumber(
    state?.commenterDetails?.commentersCount,
    state?.commenterDetails?.commentersColumn === null
  );

  const displayedModelEnv = localSelectedModel?.env ?? state.analysisDetails?.env;
  const isAnalysisOwner = !state?.analysisDetails?.sharing;
  const isViewer = currentUser.roleType === RoleType.Viewer;
  const hasContributionPermission =
    state.analysisDetails.sharing?.permissionLevel === PermissionLevel.Contribution;
  const showCommentersColumn =
    !sharingPreview && !isViewer && (isAdmin || isAnalysisOwner || hasContributionPermission);

  const fetchCommentersCount = () => {
    const fn = async () => {
      await getCommentersCount(state.analysisDetails.id)
        .then(({ data }) => {
          dispatch(
            setCommenterDetails({
              commentersColumn: data.commenterColumn,
              commentersCount: data.uniqueCommenters,
            })
          );
          if (data.commenterColumn !== state.analysisDetails.selectedCommentersColumn) {
            dispatch(
              setAnalysisDetails({
                ...state.analysisDetails,
                selectedCommentersColumn: data.commenterColumn,
              })
            );
          }
        })
        .catch((error) => console.error(error));
    };
    fn();
  };

  const selectCommentersColumn = (newColumn: string | null) => {
    dispatch(setAnalysisDetails({ ...state.analysisDetails, selectedCommentersColumn: newColumn }));
    dispatch(setCommenterDetails({ commentersColumn: newColumn, commentersCount: null }));
    updateAnalysis(state.analysisDetails.id, { commenterColumn: newColumn })
      .then(() => fetchCommentersCount())
      // TO DO: add proper error handling in later PR
      .catch((error) => console.error(error));
  };

  const handleSelectModel = (id: number) => {
    const newSelectedModel = state.analysisModels.find((am) => am.modelId === id);
    setLocalSelectedModel(state.analysisModels.find((am) => am.modelId === id));
    dispatch(setSelectedModelId(newSelectedModel.modelId));
  };

  const fetchVariableMappingName = () => {
    if (!state.analysisDetails.selectedVariableMappingName) {
      setVariableMappingName(getResource("dropdown.none"));
    } else {
      setVariableMappingName(state.analysisDetails.selectedVariableMappingName);
    }
  };

  useEffect(() => {
    if (!state.loadingAnalysisModels) {
      if (customModelId) {
        setLocalSelectedModel(
          state.analysisModels?.find((am) => am.modelId === Number(customModelId))
        );
      } else if (state.analysisDetails.graphId) {
        setLocalSelectedModel(state.analysisModels?.filter((am) => !am.isVirtual)[0]);
      } else {
        setLocalSelectedModel(null);
      }
    }
  }, [state.loadingAnalysisModels, state.analysisDetails, state.analysisModels, customModelId]);

  useEffect(() => {
    fetchVariableMappingName();
  }, [state.analysisDetails.selectedVariableMappingId]); // eslint-disable-line

  return (
    <StyledAnalysisSettings>
      <StyledTopContainer restrictedAccess={!showCommentersColumn}>
        <TotalBlockCount
          title={<Text resource="totalBlockCount.title.uniqueCommenters" />}
          displayedCount={countInfo?.isResource ? getResource(countInfo?.count) : countInfo?.count}
        >
          <Icon type={IconType.commenters} size={16} />
        </TotalBlockCount>
        {showCommentersColumn && (
          <AnalysisSettingsDropdownField
            titleKey="overview.analysisSettings.commentersId.label"
            isStringSelection={true}
            selectedOption={state.analysisDetails.selectedCommentersColumn ?? undefined}
            options={[...state.analysisDetails.allColumns].filter(
              (c) => !state.analysisDetails.selectedColumns?.some((sc) => sc === c)
            )}
            customMarginTop={"4px"}
            customWidth="100%"
            handleSelectOption={(arg: string | null) => selectCommentersColumn(arg)}
          />
        )}
      </StyledTopContainer>

      <StyledSettingsSelectionTitle>
        <Text resource="analysisSettings.modelEnv.label" />
      </StyledSettingsSelectionTitle>
      {displayedModelEnv && (
        <StyledDefaultInput>
          <span>{displayedModelEnv}</span>
        </StyledDefaultInput>
      )}

      <StyledSettingContainer>
        <StyledSettingsSelectionTitle>
          <Text resource="overview.analysisSettings.categorizationModel.label" />
        </StyledSettingsSelectionTitle>
        {localSelectedModel ? (
          <ModelDropdown
            selectedModel={localSelectedModel}
            options={state.analysisModels}
            handleSelectMenuOption={(id: number) => handleSelectModel(id)}
          />
        ) : (
          <StyledDefaultInput>
            <span>
              <Text resource="modelSelection.defaultOption" />
            </span>
          </StyledDefaultInput>
        )}
      </StyledSettingContainer>

      <StyledSettingContainer>
        <StyledSettingsSelectionTitle>
          <Text resource="overview.analysisSettings.variableMapping.label" />
        </StyledSettingsSelectionTitle>
        <StyledDefaultInput>
          <span>{variableMappingName}</span>
        </StyledDefaultInput>
      </StyledSettingContainer>
      <StyledSettingsSelectionTitle>
        <Text resource="overview.analysisSettings.feedbackColumn.label" />
      </StyledSettingsSelectionTitle>
      <ExcludedValues />
      {((isAnyAdmin(currentUser.roleType) && IsFeatureActive(Feature.Redaction)) ||
        (currentUser.roleType === RoleType.Analyst &&
          (hasContributionPermission || isAnalysisOwner))) && (
        <>
          <StyledSettingsSelectionTitle>
            <Text resource="overview.analysisSettings.redaction.label" />
          </StyledSettingsSelectionTitle>
          <Redactions />
        </>
      )}
    </StyledAnalysisSettings>
  );
};

const StyledTopContainer = styled.div<{ restrictedAccess: boolean }>`
  display: flex;
  column-gap: 20px;
  > :nth-child(2) {
    width: 270px;
  }
  margin-bottom: ${({ restrictedAccess }) => restrictedAccess && "15px"};
`;

const StyledSettingContainer = styled.div`
  margin: 10px 0;
`;

const StyledDefaultInput = styled.div`
  margin-top: 4px;
  height: 34px;
  width: 100%;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  display: flex;
  align-items: center;
  background-color: ${Color.neutral10};
  cursor: not-allowed;

  span {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 4px 8px;
    color: ${Color.gray20};
  }
`;

const StyledAnalysisSettings = styled.div`
  max-height: 600px;
  padding: 16px;
  overflow-y: auto;
`;
