import React from "react";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { useAppDispatch } from "store";
import { openRedactCommentModal } from "store/redaction/redactCommentSlice";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

export type Props = {
  commentId: number;
  isRedacted: boolean;
  disabled?: boolean;
};

export const RedactionActionButton = ({ commentId, isRedacted, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(openRedactCommentModal(commentId));
  };

  return (
    <div>
      <Button
        variant={ButtonVariant.light}
        square
        tooltipContentKey="comments.redaction.tooltip"
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{ color: isRedacted ? Color.indigo50 : Color.gray20 }}
        disabled={disabled}
      >
        <Icon type={isRedacted ? IconType.lockedFileInverted : IconType.lockedFile} size={12} />
      </Button>
    </div>
  );
};
