import React, { ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { SortingOrder } from "@explorance/mly-types";
import { Icon, IconType } from "components/_icons/Icon";
import { Checkbox } from "components/_inputs/Checkbox";
import { Color } from "ts/enums/color";
import { CheckboxCheckedAppearance } from "ts/enums/checkboxCheckedAppearance";
import { FlexTableType } from "ts/enums/flexTable";
import useTableSize from "hooks/useTableSize";
import { Skeleton } from "components/Skeleton";
import { Row } from "./Row";
import { StyledCell } from "./commonStyles";
import { PlaceholderRows } from "./PlaceholderRows";
import { DropdownMenuItem } from "ts/dropdown";
import Draggable, { DraggableData } from "react-draggable";
import { TextTruncator } from "components/TextTruncator";

export const CHECKBOX_CELL_WIDTH = "32px";

export type RowDotsMenuParams = {
  hideDotsButton?: boolean;
  rowId: number;
  currentEditedRowId: number;
  isDeleting?: boolean;
  menuContents: DropdownMenuItem[];
  altDropdownContent?: ReactElement;
  onDotMenuClick?: () => void;
  handleHideUserDetails?: () => void;
  clickToClose?: boolean;
};

// /!\ For the component to work properly, ensure the shape of `data` is coherent:
// headers.length should equal rows[i].length (for i in [0, rows.length])
// columnWidths.length should be equal to headers.length
// selectedStatuses.length should be equal to headers.length

type FlexTableData = {
  headers: ReactNode[];
  rows: {
    data: ReactNode[];
    isSelected?: boolean;
    contentId?: number;
    expandedContent?: ReactNode;
    isDisabled?: boolean;
    dotsMenuParams?: RowDotsMenuParams;
  }[];
  columnWidths: string[];
  headerMinWidths?: number[];
};

export type FlexTableSorting = {
  columnIndex: number;
  order: SortingOrder;
};

type FlexTableCustomStyles = {
  rows: {
    backgroundColor?: string;
    padding?: string;
    flexWrap?: string;
    rowHeight?: string;
  };
};

type Props = {
  data: FlexTableData;
  onSortingChange?: (updatedSorting: FlexTableSorting) => void;
  onSelectionChange?: (contentId?: number) => void;
  handleSelectAll?: () => void;
  handleClickOutside?: () => void;
  handleDoubleClickRow?: (contentId: number) => void;
  initialSorting?: FlexTableSorting;
  isSelectable?: boolean;
  customStyles?: FlexTableCustomStyles;
  maxHeight?: string;
  isLoading?: boolean;
  disableCardSelection?: boolean;
  disableSorting?: boolean;
  type: FlexTableType;
};

type Bounds = {
  left: number;
  right: number;
};

type HeaderCellProps = {
  header: ReactNode;
  index: number;
  columnWidths: number[];
  previousColumnWidths: string[];
  isLoading: boolean;
  localSorting: FlexTableSorting;
  boundsArray: Bounds[];
  setColumnWidths: (columnWidths: number[]) => void;
  toggleSorting: (index: number) => void;
  updateBounds: (index: number, data: DraggableData) => void;
  tableWidth: number;
  isSelectable: boolean;
  disableSorting: boolean;
};

const HeaderCell = ({
  header,
  index,
  columnWidths,
  setColumnWidths,
  updateBounds,
  toggleSorting,
  tableWidth,
  boundsArray,
  previousColumnWidths,
  isLoading,
  localSorting,
  isSelectable,
  disableSorting,
}: HeaderCellProps) => {
  const nameRef = useRef(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  /*
  Initial position is the css position in referance to the table, sliderPosition is in the referance of the initial position (self).
  Slider position can't be referenced to the table since the boundries would be also in reference to the table which makes it more complex.
  */
  const [initialPositionX, setInitialPositionX] = useState<number>(0);
  const [sliderPositionX, setSliderPositionX] = useState<number>(0);

  const changeWidthProportional = (data: DraggableData, index: number) => {
    const newColumnWidths = [...columnWidths];
    newColumnWidths[index] += data.deltaX;
    newColumnWidths[index + 1] -= data.deltaX;
    setColumnWidths(newColumnWidths);
  };

  useEffect(() => {
    setInitialPositionX(
      ((tableWidth - 36) / 100) * parseFloat(previousColumnWidths[index]) +
        (isSelectable ? 20 : 0) +
        previousColumnWidths.reduce((acc, width, i) => {
          if (i < index) {
            return acc + ((tableWidth - 36) / 100) * parseFloat(width);
          }
          return acc;
        }, 0)
    );
  }, [tableWidth, previousColumnWidths]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSliderPositionX(0);
  }, [isLoading]);

  return (
    <StyledHeaderCellContainer
      key={index}
      width={`${columnWidths[index]}px`}
      isLoading={isLoading}
      isDragging={isDragging}
    >
      <StyledHeaderCell
        onClick={() => !disableSorting && toggleSorting(index)}
        disableSorting={disableSorting}
      >
        {isLoading ? (
          <Skeleton width={columnWidths[index] * 0.7} height={10} backgroundColor={Color.sky50} />
        ) : (
          <div ref={nameRef}>
            <TextTruncator
              value={header}
              id={index}
              customWidth={`${columnWidths[index] - 35}px`}
            />
          </div>
        )}
        {localSorting.columnIndex === index && !isLoading && !disableSorting && (
          <Icon
            type={IconType.arrowDown}
            size={12}
            color={Color.gray50}
            style={{
              marginLeft: 5,
              transform: localSorting.order === SortingOrder.ASC ? "rotate(180deg)" : "none",
            }}
          />
        )}
      </StyledHeaderCell>
      {isLoading ||
      index === columnWidths.length - 1 ||
      index === columnWidths.length - 2 ? null : (
        <Draggable
          onStart={() => setIsDragging(true)}
          onDrag={(_, data) => {
            changeWidthProportional(data, index);
          }}
          onStop={(_, data) => {
            updateBounds(index, data);
            setSliderPositionX(data.x);
            setIsDragging(false);
          }}
          axis="x"
          scale={1}
          bounds={boundsArray[index]}
          position={{ x: sliderPositionX, y: 0 }}
        >
          <StyledHeaderSlider
            style={{
              position: "absolute",
              left: initialPositionX,
            }}
            isDragging={isDragging}
          />
        </Draggable>
      )}
    </StyledHeaderCellContainer>
  );
};

export const FlexTable = React.memo(
  ({
    data,
    initialSorting,
    onSortingChange,
    onSelectionChange,
    handleSelectAll,
    handleClickOutside,
    handleDoubleClickRow,
    maxHeight,
    disableSorting = false,
    isSelectable,
    customStyles,
    isLoading,
    disableCardSelection,
    type,
  }: Props) => {
    const [localSorting, setLocalSorting] = useState<FlexTableSorting>(
      initialSorting || { columnIndex: 0, order: SortingOrder.DESC }
    );

    const nbSelectedRows = data.rows?.filter((r) => r.isSelected).length;
    const tableSize = useTableSize();
    const [tableRowContainerYPos, setTableRowContainerYPos] = useState<number | null>(null);
    const [tableRowContainerRef, setTableRowContainerRef] = useState<HTMLDivElement | null>(null);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableWidth, setTableWidth] = useState<number | undefined>(
      type === FlexTableType.Table ? 1175 : 1140
    );
    const [localColumnWidths, setlocalColumnWidths] = useState<number[]>([]);
    const [localColumnWidthInitialised, setLocalColumnWidthInitialised] = useState<boolean>(false);
    const [boundsArray, setBoundsArray] = useState<Bounds[]>(
      [...Array(data.headers.length)].map(() => ({ left: 0, right: 0 }))
    );
    const [nameWidthArray] = useState<number[]>(
      data.headerMinWidths || [...Array(data.headers.length + 1)].fill(35)
    );

    const [initialBounds, setInitialBounds] = useState<Bounds[]>(
      [...Array(data.headers.length)].map(() => ({ left: 0, right: 0 }))
    );

    const lastElementId = JSON.parse(
      JSON.stringify(data?.rows?.[data?.rows?.length - 1]?.contentId || {})
    );

    // If maxHeight is not provided, the table will take the remaining space and 100px left for padding
    const tableMaxHeight =
      maxHeight || (tableRowContainerYPos && `calc(100vh - ${tableRowContainerYPos}px - 100px)`);

    useEffect(() => {
      if (tableRef.current && !isLoading) {
        const tableWidthLocal = tableRef.current.getBoundingClientRect().width;
        setTableWidth(tableWidthLocal);
        setlocalColumnWidths(
          data.columnWidths.map((w) => ((tableWidthLocal - 28) * parseFloat(w)) / 100)
        );
        setLocalColumnWidthInitialised(true);
      }
      if (tableRef.current && localColumnWidthInitialised && !isLoading) {
        initializeBound();
      }

      return () => {
        setLocalColumnWidthInitialised(false);
        setlocalColumnWidths([]);
        setBoundsArray([]);
        setInitialBounds([]);
      };
    }, [tableRef.current, localColumnWidthInitialised, isLoading]); // eslint-disable-line

    useEffect(() => {
      setLocalColumnWidthInitialised(false);
      setlocalColumnWidths([]);
      setBoundsArray([]);
      setInitialBounds([]);
    }, [tableSize]);

    useEffect(() => {
      if (tableRowContainerRef) {
        setTableRowContainerYPos(tableRowContainerRef.getBoundingClientRect().top);
      }
    }, [tableRowContainerRef]);

    useEffect(() => {
      if (tableRowContainerRef) {
        tableRowContainerRef.scrollTop = 0;
      }
    }, [lastElementId, tableRowContainerRef]);

    useEffect(() => {
      setLocalSorting(initialSorting || { columnIndex: 0, order: SortingOrder.DESC });
    }, [tableSize, initialSorting]);

    const updateSorting = (columnIndex: number, newOrder: SortingOrder) => {
      const newLocalSorting = { columnIndex, order: newOrder };
      setLocalSorting(newLocalSorting);
      onSortingChange && onSortingChange(newLocalSorting);
    };

    const toggleSorting = (columnIndex: number) => {
      const newOrder =
        localSorting.columnIndex === columnIndex && localSorting.order === SortingOrder.DESC
          ? SortingOrder.ASC
          : SortingOrder.DESC;
      updateSorting(columnIndex, newOrder);
    };

    const initializeBound = () => {
      const newBounds = [...boundsArray];
      data.headers.forEach((_, i) => {
        newBounds[i] = {
          left: -localColumnWidths[i] + nameWidthArray[i] + 35,
          right: localColumnWidths[i + 1] - nameWidthArray[i + 1] - 35,
        };
      });
      setInitialBounds(newBounds);
      setBoundsArray(newBounds);
    };

    const updateBounds = (index: number, data: DraggableData) => {
      const newBounds = [...boundsArray];
      newBounds[index + 1] = {
        left: initialBounds[index + 1].left + data.x,
        right: newBounds[index + 1].right,
      };
      if (index > 0) {
        newBounds[index - 1] = {
          left: newBounds[index - 1].left,
          right: initialBounds[index - 1].right + data.x,
        };
      }

      setBoundsArray(newBounds);
    };

    return (
      <StyledFlexTable type={type} ref={tableRef}>
        <StyledHeader type={type} isLoading={isLoading} className={isLoading ? "pulse" : "fade-in"}>
          {isSelectable && (
            <StyledHeaderCellContainer width={CHECKBOX_CELL_WIDTH} isSortable={!disableSorting}>
              <Checkbox
                onChange={handleSelectAll}
                checked={nbSelectedRows > 0}
                checkedAppearance={
                  nbSelectedRows < data.rows.length
                    ? CheckboxCheckedAppearance.Partial
                    : CheckboxCheckedAppearance.Default
                }
              />
            </StyledHeaderCellContainer>
          )}

          {data.headers.map((h, i) => (
            <HeaderCell
              key={i}
              header={h}
              index={i}
              columnWidths={
                isLoading
                  ? data.columnWidths.map((w) => (tableWidth * parseFloat(w)) / 100)
                  : localColumnWidths
              }
              previousColumnWidths={data.columnWidths}
              isLoading={isLoading}
              localSorting={localSorting}
              boundsArray={boundsArray}
              setColumnWidths={setlocalColumnWidths}
              toggleSorting={(index) => toggleSorting(index)}
              updateBounds={(index, data) => updateBounds(index, data)}
              tableWidth={tableWidth || 0}
              isSelectable={isSelectable}
              disableSorting={disableSorting}
            />
          ))}
        </StyledHeader>

        <StyledTableRowContainer
          ref={(ref) => setTableRowContainerRef(ref)}
          type={type}
          maxHeight={tableMaxHeight}
        >
          {isLoading ? (
            <PlaceholderRows
              columnWidths={data.columnWidths.map((w) => (tableWidth * parseFloat(w)) / 100)}
              type={type}
              customStyles={customStyles?.rows}
              tableWidth={tableWidth || 0}
            />
          ) : (
            data.rows?.map((r) => (
              <Row
                key={r.contentId}
                type={type}
                row={r.data}
                contentId={r.contentId}
                isSelected={r.isSelected}
                isSelectable={isSelectable}
                customStyles={customStyles?.rows}
                columnWidths={localColumnWidths}
                isDisabled={r.isDisabled}
                disableCardSelection={disableCardSelection}
                expandedContent={r.expandedContent}
                handleClickOutside={handleClickOutside}
                onSelectionChange={onSelectionChange}
                dotsMenuParams={r.dotsMenuParams}
                isLoading={isLoading}
                handleDoubleClickRow={handleDoubleClickRow}
                tableWidth={tableWidth}
              />
            ))
          )}
        </StyledTableRowContainer>
      </StyledFlexTable>
    );
  }
);

// TABLE
const StyledFlexTable = styled.div<{ type: FlexTableType }>`
  border: ${({ type }) => (type === FlexTableType.Card ? "none" : `1px solid ${Color.blue20}`)};
  border-bottom: 0;
`;

// TABLE ITEMS
const StyledHeader = styled.div<{ type: FlexTableType; isLoading: boolean }>`
  display: flex;
  background-color: ${Color.sky20};
  user-select: none;
  font-weight: bold;
  font-size: 14px;
  position: sticky;
  overflow: hidden;
  padding: ${({ isLoading }) => isLoading && "10px 0px"};
  top: 0;
  border-bottom: ${({ type }) =>
    type === FlexTableType.Card ? "none" : `1px solid ${Color.blue20}`};
`;

const StyledHeaderCellContainer = styled(StyledCell)<{
  isLoading?: boolean;
  isSortable?: boolean;
  isDragging?: boolean;
}>`
  display: flex;
  width: 100%;

  padding: ${({ isLoading }) => !isLoading && "14px 18px 14px 10px"};
  height: ${({ isLoading }) => isLoading && "17px"};
  align-items: center;
  background-color: ${({ isDragging, isLoading }) =>
    isLoading ? "none" : isDragging ? Color.sky30 : Color.sky20};
  cursor: ${({ isLoading, isSortable }) => (isLoading || !isSortable ? "default" : "pointer")};
`;

const StyledHeaderCell = styled.div<{ disableSorting: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2px;
  cursor: ${({ disableSorting }) => (disableSorting ? "default" : "pointer")};
`;

const StyledTableRowContainer = styled.div<{
  maxHeight?: string;
  type: FlexTableType;
}>`
  overflow-y: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  max-height: ${({ maxHeight }) => maxHeight};
  gap: ${({ type }) => (type === FlexTableType.Card ? "10px" : "0px")};
  padding-top: ${({ type }) => (type === FlexTableType.Card ? "10px" : "0px")};
`;

const StyledHeaderSlider = styled.div<{ isDragging?: boolean }>`
  cursor: col-resize;
  width: 3px;
  height: 100%;
  background-color: ${({ isDragging }) => (isDragging ? Color.blue40 : Color.blue20)};
  display: flex;
  opacity: 0.5;
  align-self: center;
  border-radius: 2px;
`;
