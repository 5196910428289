import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import { CloseButton } from "components/_buttons/CloseButton";
import { Modal } from "components/_modals/Modal";
import { LoadingDots } from "components/LoadingDots";
import { Text } from "components/Text";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { ManageableRequest } from "./ManageableRequest";
import { ApprovalInfo } from "./ApprovalInfo";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeModal,
  setAnalysisName,
  setCommentId,
  setSelectedRequestId,
} from "store/redaction/redactionRequestManagementSlice";
import {
  fetchRedactionRequests,
  fetchRedactionRequestsForComment,
  patchRedactionRequest,
  patchRedactionRequests,
} from "store/redaction/thunks";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { RequestsView } from "ts/enums/requestListView";
import {
  RedactionAction,
  RedactionDisplayMethod,
  RedactionRequestStatus,
} from "@explorance/mly-types";

const TOP_STICKY_SECTION_HEIGHT = 60;
const BOTTOM_STICKY_SECTION_HEIGHT = 126;

export const RedactionRequestManagementModal = () => {
  const state = useAppSelector((state) => state.redactionRequestManagement);
  const requestListState = useAppSelector((state) => state.redactionRequestList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (requestListState.listView === RequestsView.MyRequests) return;
    dispatch(fetchRedactionRequestsForComment());
  }, [dispatch, requestListState.listView]);

  const isSelectedRequestApproved =
    state.selectedRequestId &&
    state.selectedRequestId ===
      state.requests?.find((request) => request.status === RedactionRequestStatus.Approved)
        ?.requestId;

  const getNextPrevCommentId = (index: number) => {
    const nextCommentId =
      requestListState.requestsUniqueCommentIds[
        requestListState.requestsUniqueCommentIds.findIndex((id) => state.commentId === id) + index
      ];

    const uniqueFiltered = Array.from(
      new Set(requestListState.selectedRedactionRequests.map((req) => req.commentId))
    );

    const nextBulkCommentId =
      uniqueFiltered[uniqueFiltered.findIndex((id) => state.commentId === id) + index];

    return requestListState.selectedRedactionRequests.length !== 0
      ? nextBulkCommentId
      : nextCommentId;
  };

  const loadNextPrevRequest = (index: number) => {
    const commentId = getNextPrevCommentId(index);
    const targetRequest =
      requestListState.selectedRedactionRequests.length === 0
        ? requestListState.redactionRequests.find((req) => req.commentId === commentId)
        : requestListState.selectedRedactionRequests.find((req) => req.commentId === commentId);
    dispatch(setCommentId(commentId));
    dispatch(setSelectedRequestId(targetRequest.requestId));
    dispatch(setAnalysisName(targetRequest.analysisName));
    dispatch(fetchRedactionRequestsForComment());
  };

  const handleApprove = async () => {
    if (isSelectedRequestApproved) return;

    try {
      await dispatch(
        patchRedactionRequest({
          redactionRequestId: state.selectedRequestId,
          redactionAction: RedactionAction.Approve,
          ...(state.editedRequest && {
            editedDisplayMethod: state.editedRequest.redactionDisplayMethod,
          }),
          ...(state.editedRequest?.redactionDisplayMethod === RedactionDisplayMethod.Partial && {
            editedValue: state.editedRequest.redactedValue,
          }),
        })
      );
      await dispatch(fetchRedactionRequests());
      await dispatch(fetchRedactionRequestsForComment());
    } catch (error) {
      console.error(error);
    }
  };

  const handleReject = async () => {
    if (state.requests.every((request) => request.status === RedactionRequestStatus.Rejected)) {
      return;
    }

    try {
      await dispatch(
        patchRedactionRequests({
          requestIds: state.requests.map((request) => request.requestId),
          redactionAction: RedactionAction.Reject,
        })
      );
      await dispatch(fetchRedactionRequests());
      await dispatch(fetchRedactionRequestsForComment());
    } catch (error) {
      console.error(error);
    }
  };

  const mainReviewedRequestExists =
    state.requests &&
    (state.requests.some((request) => request.status === RedactionRequestStatus.Approved) ||
      (!state.requests.some((request) => request.status === RedactionRequestStatus.Approved) &&
        state.requests.some((request) => request.status === RedactionRequestStatus.Rejected)));

  return (
    <Modal
      isOpen={state.isModalOpen}
      width="848px"
      height="auto"
      padding="0 24px"
      margin="3% auto"
      maxHeight="80vh"
      // This ensures we see toast message over the backdrop
      overlayIndex={ZIndexStackingContext.low}
    >
      <StyledHeaderSection>
        <h2>
          <Text resource="comments.redactModal.header" />
        </h2>
        <CloseButton titleKey="button.close" closeHandler={() => dispatch(closeModal())} />
      </StyledHeaderSection>
      {state.isLoading ? (
        <StyledLoadingDotsContainer>
          <LoadingDots />
        </StyledLoadingDotsContainer>
      ) : (
        <>
          <StyledMiddleSection>
            <StyledOriginalCommentSection>
              <StyledGeneralInfo>
                <h3>
                  <Text resource="redactionRequestManagementModal.fromAnalysis" />{" "}
                  {state.analysisName}
                </h3>
                <span>
                  <b>
                    <Text
                      resource={{
                        key: "comments.outOf",
                        args: [
                          `${state.totalRequestsCount}`,
                          `${requestListState.totalRedactionRequestCount}`,
                        ],
                      }}
                    />
                  </b>{" "}
                  <Text resource="searchBar.dataType.redaction.request.plural" />
                </span>
              </StyledGeneralInfo>
              <h3>
                <Text resource="comments.redactModal.originalComment.header" />
              </h3>
              <StyledOriginalValue>{state.requests[0].originalValue}</StyledOriginalValue>
            </StyledOriginalCommentSection>
            <StyledRequestsSection>
              <h3>
                <Text
                  resource={{
                    key:
                      state.totalRequestsCount > 1
                        ? "comments.redactModal.requests.header.plural"
                        : "comments.redactModal.requests.header.singular",
                    args: [`${state.totalRequestsCount}`],
                  }}
                />
              </h3>
              {state.requests.map((request, index) => (
                <ManageableRequest
                  key={index}
                  request={request}
                  isSingle={state.requests.length === 1}
                />
              ))}
            </StyledRequestsSection>
          </StyledMiddleSection>
          <StyledBottomSection
            showActionButtons={
              mainReviewedRequestExists || requestListState.listView !== RequestsView.MyRequests
            }
          >
            <StyledApprovalSection>
              {mainReviewedRequestExists ? <ApprovalInfo /> : <div />}

              {requestListState.listView !== RequestsView.MyRequests && (
                <StyledApprovalButtons>
                  <Button
                    variant={ButtonVariant.outline}
                    size={ButtonSize.md}
                    onClick={handleReject}
                  >
                    <Text
                      resource={
                        state.requests.length >= 2
                          ? "redaction.request.rejectAll"
                          : "redaction.request.reject"
                      }
                    />
                  </Button>
                  <Button
                    variant={
                      isSelectedRequestApproved ? ButtonVariant.primary : ButtonVariant.outlineBlue
                    }
                    size={ButtonSize.md}
                    onClick={handleApprove}
                  >
                    <Text
                      resource={
                        isSelectedRequestApproved
                          ? "redaction.request.status.[approved]"
                          : state.requests.length >= 2
                          ? "redaction.request.approveAndRejectOthers"
                          : "redaction.request.approve"
                      }
                    />
                    {isSelectedRequestApproved && (
                      <Icon type={IconType.check} size={14} style={{ marginLeft: 5 }} />
                    )}
                  </Button>
                </StyledApprovalButtons>
              )}
            </StyledApprovalSection>
            <StyledRequestNavigationButtons>
              <Button
                variant={ButtonVariant.light}
                size={ButtonSize.sm}
                disabled={!getNextPrevCommentId(-1)}
                onClick={() => loadNextPrevRequest(-1)}
              >
                <Icon type={IconType.chevronLeft} size={12} style={{ marginRight: 5 }} />
                <Text resource="button.previousRequest" />
              </Button>
              <Button
                variant={ButtonVariant.light}
                size={ButtonSize.sm}
                disabled={!getNextPrevCommentId(1)}
                onClick={() => loadNextPrevRequest(1)}
              >
                <Text resource="button.nextRequest" />
                <Icon type={IconType.chevronRight} size={12} style={{ marginLeft: 5 }} />
              </Button>
            </StyledRequestNavigationButtons>
          </StyledBottomSection>
        </>
      )}
    </Modal>
  );
};

const StyledMiddleSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: calc(100% - ${TOP_STICKY_SECTION_HEIGHT}px - ${BOTTOM_STICKY_SECTION_HEIGHT}px);
  overflow-y: auto;
`;

const StyledRequestNavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHeaderSection = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.gray50};
  height: ${TOP_STICKY_SECTION_HEIGHT}px;
  background-color: ${Color.white};
  z-index: ${ZIndexStackingContext.low};
  h2 {
    font-size: 20px;
    margin: 0;
  }
`;

const StyledGeneralInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 16px 0;
  h3 {
    font-size: 16px;
  }
  span {
    font-size: 14px;
    color: ${Color.gray50};
  }
`;

const StyledBottomSection = styled.div<{ showActionButtons: boolean }>`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${Color.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 0;

  ${({ showActionButtons }) =>
    showActionButtons &&
    css`
      height: ${BOTTOM_STICKY_SECTION_HEIGHT}px;
    `}
`;

const StyledApprovalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const StyledApprovalSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledOriginalCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-size: 16px;
    margin: 0;
    color: ${Color.gray50};
  }
`;

const StyledOriginalValue = styled.p`
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin: 0;
  color: ${Color.gray40};
  font-size: 14px;
  background-color: ${Color.neutral20};
  max-height: 150px;
  overflow-y: auto;
`;

const StyledLoadingDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 271px;
`;

const StyledRequestsSection = styled.div`
  h3 {
    font-size: 16px;
    margin: 0;
    color: ${Color.gray50};
    margin-bottom: 16px;
  }
`;
