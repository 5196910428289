import React from "react";
import styled from "styled-components";
import { Pill } from "components/_pills/Pill";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";
import { RedactionRequestStatus } from "@explorance/mly-types";

const STATUS_COLOR_MAP = {
  [RedactionRequestStatus.Approved]: {
    captionColor: Color.green50,
    backgroundColor: Color.green10,
  },
  [RedactionRequestStatus.ApprovedAndEdited]: {
    captionColor: Color.green50,
    backgroundColor: Color.green10,
  },
  [RedactionRequestStatus.Pending]: {
    captionColor: Color.orange90,
    backgroundColor: Color.orange10,
  },
  [RedactionRequestStatus.Rejected]: {
    captionColor: Color.red55,
    backgroundColor: Color.red10,
  },
};

type Props = {
  status: RedactionRequestStatus;
};

export const RequestStatusPill = ({ status }: Props) => (
  <Pill
    backgroundColor={STATUS_COLOR_MAP[status].backgroundColor}
    color={STATUS_COLOR_MAP[status].captionColor}
    style={{ fontSize: 12, fontWeight: "bold", padding: "8px 12px", whiteSpace: "nowrap" }}
  >
    <StyledStatusCircle color={STATUS_COLOR_MAP[status].captionColor} />
    <Text resource={`redaction.request.status.[${status}]`} />
  </Pill>
);

const StyledStatusCircle = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;
