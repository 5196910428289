import styled from "styled-components";
import { FlexTableType } from "ts/enums/flexTable";

export const StyledBaseRow = styled.div<{
  customStyles?: Record<string, string>;
}>`
  display: flex;
  align-items: center;
`;

export const StyledCell = styled.div<{ width?: string; type?: FlexTableType }>`
  flex-grow: 1;
  padding: 15px 0px;
  flex-basis: ${({ width }) => width || "auto"};
  min-width: ${({ width }) => width || "auto"};
  max-width: ${({ width }) => width || "auto"};
  font-size: 14px;

  &:first-child {
    padding-left: 15px;
  }

  &:last-child {
    padding-right: 15px;
  }

  div > span {
    font-size: ${({ type }) => type === FlexTableType.Table && "12px"};
  }
`;
