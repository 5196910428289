import React, { useEffect } from "react";
import styled from "styled-components";
import * as download from "downloadjs";
import { lowerFirst } from "lodash";
import { BlobServiceClient } from "@azure/storage-blob";

import { useResource } from "hooks/useResource";
import { deleteDownloadedFileById, retryDownloadedFileById } from "services/downloadedFiles";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import { getStorageConfig } from "config/configProviders";
import { useAppSelector, useAppDispatch } from "store";
import { fetchDownloadedFiles } from "store/exportedFiles/thunks";
import {
  clearState,
  setCurrentEditedRow,
  setCurrentPage,
  setDropdownOpen,
  setShowDeleteConfirmation,
  updateExportedFilesListSorting,
} from "store/exportedFiles/exportedFilesListSlice";
import { setSearchTerm } from "store/search/searchbarSlice";

import { StyledPaginationContainer } from "pages/custom-models/_layouts/Header";
import { ListPageHeaderPlaceholder } from "common-layouts/ListPagePlaceholder/ListPageHeaderPlaceholder";

import { SearchRow } from "components/SearchRow";
import { Pagination } from "components/Pagination";
import { EmptyState } from "components/EmptyState";
import { StyledTablePageHeader, StyledTablePageTitle } from "components/Table/TablePageHeader";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { TextTruncator } from "components/TextTruncator";
import { CategorizationTypeInfo } from "components/CategorizationTypeInfo";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { TableInformation } from "components/Table/ClickableTableHeader";
import { Text } from "components/Text";

import emptyImage from "assets/images/empty-custom-models.svg";
import { DownloadedFile } from "ts/downloadedFiles";
import { FlexTableType } from "ts/enums/flexTable";
import { ExportStatus } from "ts/enums/export";
import { Color } from "ts/enums/color";
import { ExportsHistorySortingParameter, SocketEvent } from "@explorance/mly-types";

const blobStorageConfig = getStorageConfig();

const statusColorMap = {
  [ExportStatus.Ready]: Color.green50,
  [ExportStatus.Pending]: Color.orange50,
  [ExportStatus.InProgress]: Color.blue50,
  [ExportStatus.Failed]: Color.red55,
};

const HISTORY_TABLE_HEADERS: TableInformation<ExportsHistorySortingParameter>[] = Object.values(
  ExportsHistorySortingParameter
).map((x) => ({
  headerName: x,
  sortingParameter: x,
}));

export const DownloadedFilesPage = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.exportedFilesList);

  const socket = useAppSelector((state) => state.wsStream.socket);

  const { getResource } = useResource();

  const handleDeleteFile = async () => {
    await deleteDownloadedFileById(state.currentEditedRow.id)
      .then(() => {
        dispatch(setShowDeleteConfirmation(false));
        dispatch(fetchDownloadedFiles());
      })
      .catch((err) => console.error(err));
  };

  const onClickDownload = () => {
    dispatch(setDropdownOpen(true));
    const data = state.currentEditedRow.linkInfo;
    if (data) {
      const blobServiceClient = new BlobServiceClient(
        `${blobStorageConfig.account}?${data.sharedAccessSignature}`
      );
      const containerClient = blobServiceClient.getContainerClient(data.containerName);
      const blobClient = containerClient.getBlockBlobClient(data.blobName);
      blobClient.download().then((blob) => {
        blob.blobBody
          .then((body) => {
            download(
              body,
              data.blobName,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            );
          })
          .finally(() => dispatch(setDropdownOpen(false)));
      });
    }
  };

  const onClickRetry = async () => {
    dispatch(setDropdownOpen(true));
    await retryDownloadedFileById(state.currentEditedRow.id);
    dispatch(fetchDownloadedFiles());
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
    dispatch(fetchDownloadedFiles());
  };

  const handleSearch = () => {
    dispatch(setCurrentPage(1));
    dispatch(fetchDownloadedFiles());
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const sortColumn = HISTORY_TABLE_HEADERS[updatedSorting.columnIndex].sortingParameter;
    dispatch(updateExportedFilesListSorting({ sortColumn, sortOrder: updatedSorting.order }));
    dispatch(fetchDownloadedFiles());
  };

  const getMenuContents = () => {
    return [
      state.currentEditedRow.status === ExportStatus.Ready && {
        label: <Text resource="downloadedFiles.table.dropdown.download" />,
        onClick: () => onClickDownload(),
      },
      state.currentEditedRow.status === ExportStatus.Failed && {
        label: <Text resource="downloadedFiles.table.dropdown.retry" />,
        onClick: () => onClickRetry(),
      },
      {
        label: <Text resource="downloadedFiles.table.dropdown.delete" />,
        onClick: () => dispatch(setShowDeleteConfirmation(true)),
      },
    ].filter(Boolean);
  };

  useEffect(() => {
    if (!socket) return;

    const handleDownloadedFilesUpdate = () => {
      dispatch(fetchDownloadedFiles());
    };

    socket.on(SocketEvent.ChangedExport, handleDownloadedFilesUpdate);

    return () => {
      socket.off(SocketEvent.ChangedExport, handleDownloadedFilesUpdate);
    };
  }, [dispatch, socket]);

  useEffect(() => {
    dispatch(fetchDownloadedFiles());

    // Reset state when component unmounts
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]); // eslint-disable-line

  const statusCellElement = (downloadedFile: DownloadedFile) => {
    return (
      <StyledExportStatusContainer key={`status-${downloadedFile.id}`}>
        <StyledStatusCircle color={statusColorMap[downloadedFile.status]} />
        <span>
          <Text
            resource={{
              key: `downloadedFiles.table.status.${lowerFirst(downloadedFile.status)}`,
            }}
          />
        </span>
      </StyledExportStatusContainer>
    );
  };

  return (
    <>
      {state.isLoading ? (
        <ListPageHeaderPlaceholder
          showCreateButton={false}
          showFilterButton={false}
          applyMorePadding
          isSingleRowHeader
        />
      ) : (
        <div className="fade-in">
          <ActionConfirmationModal
            isOpen={state.showDeleteConfirmation}
            title={<Text resource="modal.deleteDownloadedFile.title" />}
            caption={<Text resource="modal.deleteDownloadedFile.caption" />}
            actionButtonLabel={<Text resource="button.delete" />}
            onClose={() => dispatch(setShowDeleteConfirmation(false))}
            onCancel={() => dispatch(setShowDeleteConfirmation(false))}
            onClickActionButton={handleDeleteFile}
          />
          <StyledDownloadedFilesPageHeader>
            <StyledDownloadedFilesPageTitle>
              <Text resource="downloadedFiles.table.fileName" />
            </StyledDownloadedFilesPageTitle>
            <SearchRow
              currentCount={state.currentFileCount}
              totalCount={state.totalFileCount}
              dataType="searchBar.dataType.downloadedFiles"
              placeholderText={getResource("downloadedFiles.searchBarPlaceholder")}
              customStyles={{ placeholderTextSize: "0.875em" }}
              onSearch={handleSearch}
            />
            <StyledPaginationContainer>
              {state.currentFileCount > 0 && (
                <Pagination
                  currentPage={state.currentPage}
                  currentItemsCount={state.currentFileCount}
                  handlePageSelection={(page) => handleChangePage(page)}
                />
              )}
            </StyledPaginationContainer>
          </StyledDownloadedFilesPageHeader>
        </div>
      )}
      {state.totalFileCount > 0 || state.isLoading ? (
        <FlexTable
          type={FlexTableType.Table}
          data={{
            headers: HISTORY_TABLE_HEADERS.map((x, i) => (
              <Text key={i} resource={`downloadedFiles.table.[${x.headerName}]`} />
            )).concat(<></>),
            rows: state.exportedFilesList?.map((df) => ({
              contentId: df.id,
              data: [
                <TextTruncator
                  value={df.fileName}
                  id={df.id}
                  key={`fileName-${df.id}`}
                  customWidth="85%"
                />,
                <TextTruncator
                  value={df.analysisName}
                  id={df.id}
                  key={`analysisName-${df.id}`}
                  customWidth="85%"
                />,
                statusCellElement(df),
                <CategorizationTypeInfo family={df.family} key={`categorization-${df.id}`} />,
                <TextTruncator
                  value={getInternationalDateFormat(getDayMonthYearFromDate(df.dateGenerated))}
                  id={df.id}
                  key={`dateGenerated-${df.id}`}
                  customWidth="85%"
                />,
              ],
              dotsMenuParams: {
                hideDotsButton: df.status === ExportStatus.Pending,
                rowId: df.id,
                currentEditedRowId: state.currentEditedRow?.id,
                menuContents: state.currentEditedRow ? getMenuContents() : [],
                onDotMenuClick: () => dispatch(setCurrentEditedRow(df)),
                clickToClose: state.closeDropdown && !state.showDeleteConfirmation && true,
              },
            })),
            columnWidths: ["25%", "25%", "14%", "17%", "16%", "3%"],
          }}
          customStyles={{
            rows: {
              padding: "15px",
              backgroundColor: Color.white,
            },
          }}
          initialSorting={{
            columnIndex: HISTORY_TABLE_HEADERS.findIndex(
              (x) => x.sortingParameter === state.sortColumn
            ),
            order: state.sortOrder,
          }}
          onSortingChange={updateSorting}
          isLoading={state.isLoading}
        />
      ) : (
        <EmptyState
          type={state.emptyStateType}
          image={emptyImage}
          titleKey={"emptyState.noDownloadedFiles.title"}
          captionKey={"emptyState.noDownloadedFiles.caption"}
          handleClickCaptionLink={() => dispatch(setSearchTerm(""))}
        />
      )}
    </>
  );
};

const StyledDownloadedFilesPageHeader = styled(StyledTablePageHeader)`
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const StyledDownloadedFilesPageTitle = styled(StyledTablePageTitle)`
  font-size: 1em;
`;

const StyledStatusCircle = styled.div<{ color: string }>`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: ${({ color }) => color};
  margin-right: 6px;
`;

const StyledExportStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;
