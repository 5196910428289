import {
  ManualRedactionPermission,
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DefaultWidgets } from "ts/enums/sharing";
import { fetchSharingDraft } from "./thunks";
import { SharingDraftPermissions } from "ts/permissions";

type permissionsState = {
  permissionFields: SharingDraftPermissions;
  showCustomConditionError: boolean;
  showRedactionCustomConditionError: boolean;
  showGroupNameError: boolean;
  loadingSharingDraft: boolean;
};

const initialState: permissionsState = {
  permissionFields: {
    groupName: "",
    permissionLevel: PermissionLevel.View,
    defaultWidgets: DefaultWidgets.None,
    dashboardAccess: SharingDashboardAccess.Hidden,
    commentExplorerAccess: SharingCommentExplorerAccess.Hidden,
    topicExplorerAccess: SharingTopicExplorerAccess.Hidden,
    allowExportData: false,
    visibleDemographicsFilters: [],
    visibleCommentsByDemographicValues: [],
    visibleRedactionVersion: null,
    customDemographicConditions: [],
    redactionCustomConditions: [],
    manualRedactionPermission: ManualRedactionPermission.Denied,
    allowPin: false,
  },
  showCustomConditionError: false,
  showRedactionCustomConditionError: false,
  showGroupNameError: false,
  loadingSharingDraft: true,
};

const permissionsSlice = createSlice({
  name: "permissionsSlice",
  initialState: initialState,
  reducers: {
    setPermissionFields: (state, action: PayloadAction<permissionsState["permissionFields"]>) => {
      state.permissionFields = action.payload;
    },
    setShowCustomConditionError: (state, action: PayloadAction<boolean>) => {
      state.showCustomConditionError = action.payload;
    },
    setShowRedactionCustomConditionError: (state, action: PayloadAction<boolean>) => {
      state.showRedactionCustomConditionError = action.payload;
    },
    setShowGroupNameError: (state, action: PayloadAction<boolean>) => {
      state.showGroupNameError = action.payload;
    },
    clearPermissionsState: (state, action: PayloadAction<string>) => {
      state.permissionFields = {
        ...initialState.permissionFields,
        groupName: action.payload,
      };
      state.showCustomConditionError = initialState.showCustomConditionError;
      state.showGroupNameError = initialState.showGroupNameError;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSharingDraft.fulfilled, (state, action) => {
      state.permissionFields = { ...state.permissionFields, ...action.payload };
      state.loadingSharingDraft = false;
    });
  },
});

export const {
  setPermissionFields,
  setShowCustomConditionError,
  setShowGroupNameError,
  setShowRedactionCustomConditionError,
  clearPermissionsState,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
