import { GetResourceFunction } from "./useResource";

export const useGetModelName = (getResource: GetResourceFunction) => {
  return (model): string => {
    if (!model) return getResource("categorizationType.baseModel.acronym");

    if (model.customModelId) return model.modelName;

    return getResource(`categorizationType.${model.categorizationType}.name`);
  };
};
