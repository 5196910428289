import { SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RedactionVersionsListSortingParameter } from "ts/enums/sorting";
import { RedactionVersion } from "ts/redaction";
import { fetchRedactionVersionsLists } from "./thunks";

type RedactionListSorting = {
  sortColumn: RedactionVersionsListSortingParameter;
  sortOrder: SortingOrder;
};
type RedactionListState = {
  redactionVersions: RedactionVersion[];
  currentPage: number;
  totalRedactionVersionsCount: number;
  currentRedactionVersionsCount: number;
  searchField: string;
  sortColumn: RedactionVersionsListSortingParameter;
  sortOrder: SortingOrder;
  isSearchReset: boolean;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  currentEditedRow: RedactionVersion;
  isLoading: boolean;
};

const initialState: RedactionListState = {
  redactionVersions: [],
  currentPage: 1,
  totalRedactionVersionsCount: 0,
  currentRedactionVersionsCount: 0,
  searchField: "",
  sortColumn: RedactionVersionsListSortingParameter.LastUpdated,
  sortOrder: SortingOrder.DESC,
  isSearchReset: false,
  emptyStateType: EmptyStateType.noListResults,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  isLoading: true,
};

const RedactionListSlice = createSlice({
  name: "redactionVersionsListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    setIsSearchReset: (state, action: PayloadAction<boolean>) => {
      state.isSearchReset = action.payload;
    },
    updateRedactionListSorting: (state, action: PayloadAction<RedactionListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setCurrentEditedRow: (state, action: PayloadAction<RedactionVersion>) => {
      state.currentEditedRow = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedactionVersionsLists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.redactionVersions = action.payload.redactionVersions;
      state.currentRedactionVersionsCount = action.payload.itemCount;
      state.totalRedactionVersionsCount = action.payload.totalCount;
    });
    builder.addCase(fetchRedactionVersionsLists.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  updateRedactionListSorting,
  setCurrentPage,
  setSearchField,
  setIsSearchReset,
  setCurrentEditedRow,
  setEmptyStateType,
  clearState,
} = RedactionListSlice.actions;
export default RedactionListSlice.reducer;
