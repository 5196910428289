import { RedactionListSortingParameter, SortingOrder } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { RedactionSetItem } from "ts/redaction";
import { fetchRedactionLists, fetchRedactionRequestCount } from "./thunks";

type RedactionListSorting = {
  sortColumn: RedactionListSortingParameter;
  sortOrder: SortingOrder;
};
type RedactionListState = {
  redactionRequestCount: number;
  redactionSets: RedactionSetItem[];
  currentPage: number;
  totalRedactionCount: number;
  currentRedactionCount: number;
  searchField: string;
  sortColumn: RedactionListSortingParameter;
  sortOrder: SortingOrder;
  isSearchReset: boolean;
  emptyStateType: EmptyStateType;
  showDeleteConfirmation: boolean;
  currentEditedRow: RedactionSetItem;
  isLoading: boolean;
};

const initialState: RedactionListState = {
  redactionRequestCount: 0,
  redactionSets: [],
  currentPage: 1,
  totalRedactionCount: 1,
  currentRedactionCount: 1,
  searchField: "",
  sortColumn: RedactionListSortingParameter.LastUpdated,
  sortOrder: SortingOrder.DESC,
  isSearchReset: false,
  emptyStateType: EmptyStateType.noListResults,
  showDeleteConfirmation: false,
  currentEditedRow: null,
  isLoading: true,
};

const RedactionListSlice = createSlice({
  name: "redactionListSlice",
  initialState,
  reducers: {
    setShowDeleteConfirmation: (state, action: PayloadAction<boolean>) => {
      state.showDeleteConfirmation = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSearchField: (state, action: PayloadAction<string>) => {
      state.searchField = action.payload;
    },
    setIsSearchReset: (state, action: PayloadAction<boolean>) => {
      state.isSearchReset = action.payload;
    },
    updateRedactionListSorting: (state, action: PayloadAction<RedactionListSorting>) => {
      state.currentPage = 1;
      state.sortOrder = action.payload.sortOrder;
      state.sortColumn = action.payload.sortColumn;
    },
    setCurrentEditedRow: (state, action: PayloadAction<RedactionSetItem>) => {
      state.currentEditedRow = action.payload;
    },
    setEmptyStateType: (state, action: PayloadAction<EmptyStateType>) => {
      state.emptyStateType = action.payload;
    },
    clearState: (state) => ({
      ...initialState,
      redactionRequestCount: state.redactionRequestCount,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedactionLists.fulfilled, (state, action) => {
      state.redactionSets = action.payload.redactionSets;
      state.currentRedactionCount = action.payload.itemCount;
      state.totalRedactionCount = action.payload.totalCount;
      state.isLoading = false;
    });
    builder.addCase(fetchRedactionLists.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRedactionRequestCount.fulfilled, (state, action) => {
      state.redactionRequestCount = action.payload;
    });
  },
});
export const {
  setShowDeleteConfirmation,
  updateRedactionListSorting,
  setCurrentPage,
  setSearchField,
  setIsSearchReset,
  setCurrentEditedRow,
  setEmptyStateType,
  clearState,
} = RedactionListSlice.actions;
export default RedactionListSlice.reducer;
