import React, { useState, useEffect, useRef, Ref } from "react";
import styled from "styled-components";

import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";

import { routes } from "routes";

import { useQueryParams } from "hooks/useQueryParams";
import { isAnyAdmin } from "utils/isAnyAdmin";

import { SidebarItem } from "./SidebarItem";
import { UserProfileMenu } from "components/Sidebar/UserProfileMenu";
import { NotificationItem } from "./NotificationItem";
import { NotificationCenter } from "components/NotificationCenter";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";

import mlyLogoText from "assets/images/mly-logo-text.svg";
import { APP_CSS } from "assets/constants/appCss";

import { NotificationReadStatus, RoleType } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { fetchRedactionRequestCount } from "store/redaction/thunks";
import { useResource } from "hooks/useResource";
import { DropdownMenuItem } from "ts/dropdown";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";

export const NOTIFICATION_COUNT_LIMIT = 99;

export const Sidebar = () => {
  // redux
  const { currentUser } = useAppSelector((state) => state.auth);
  const { redactionRequestCount } = useAppSelector((state) => state.redactionList);
  const { notifications } = useAppSelector((state) => state.wsStream);
  const dispatch = useAppDispatch();
  const { getResource } = useResource();

  const [showExpandChevron, setShowExpandChevron] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showNotificationCenter, setShowNotificationCenter] = useState<boolean>(false);

  const { location } = useHistory();

  // Exceptional use of useRef to ensure correct click outside behavior in useEffect on line 41
  const sidebarRef: Ref<HTMLDivElement> = useRef();
  const notificationCenterRef: Ref<HTMLDivElement> = useRef();
  const notificationCenterButtonRef: Ref<HTMLDivElement> = useRef();
  const { sharingPreview } = useQueryParams();

  const customizeItems = [
    {
      label: <Text resource="sidebar.customizations.customModels" />,
      href: routes.customizeCustomModelsPage,
      isActive: location.pathname.startsWith(routes.customizeCustomModelsPage),
    },
    {
      label: <Text resource="sidebar.customizations.variableMappingGroups" />,
      href: routes.customizeVariableMappingsPage,
      isActive: location.pathname.startsWith(routes.customizeVariableMappingsPage),
    },
  ];

  const redactionItems = [
    {
      label: <Text resource="sidebar.redaction.redactionList" />,
      href: routes.redactionListPage,
      isActive: location.pathname.startsWith(routes.redactionListPage),
      show: currentUser.roleType !== RoleType.Viewer,
    },
    {
      label: (
        <StyledRequestsItem>
          <Text resource="sidebar.redaction.redactionRequests" />
          {redactionRequestCount > 0 && redactionRequestCount && (
            <StyledNotificationCount>
              {redactionRequestCount > 0 && redactionRequestCount < NOTIFICATION_COUNT_LIMIT
                ? redactionRequestCount > 0 && redactionRequestCount
                : getResource("notification.limit")}
            </StyledNotificationCount>
          )}
        </StyledRequestsItem>
      ),
      href: routes.redactionRequestList,
      isActive: location.pathname.startsWith(routes.redactionRequestList),
    },
    {
      label: <Text resource="sidebar.redaction.redactionVersions" />,
      href: routes.redactionVersionsList,
      isActive: location.pathname.startsWith(routes.redactionVersionsList),
      show: isAnyAdmin(currentUser.roleType),
    },
  ] as DropdownMenuItem[];

  useEffect(() => {
    if (!sharingPreview) return;

    const sidebar = document.getElementById("sidebar");
    const mainContent: HTMLElement = document.querySelector(".main-content");

    if (sidebar) sidebar.style.display = "none";
    if (mainContent) mainContent.style.left = "0px";

    return () => {
      if (sidebar) sidebar.style.display = APP_CSS["[#sidebar].display"];
      if (mainContent) mainContent.style.left = APP_CSS["[.main-content].left"];
    };
  }, [sharingPreview]);

  useEffect(() => {
    if (!isExpanded && showNotificationCenter) setShowNotificationCenter(false);
  }, [isExpanded]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchRedactionRequestCount());
  }, [dispatch]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      // close sidebar if neither sidebar nor notification center were clicked
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        notificationCenterRef.current &&
        !notificationCenterRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
        // close notification center if neither notification center nor button were clicked
      }
      if (
        notificationCenterRef.current &&
        !notificationCenterRef.current.contains(event.target) &&
        notificationCenterButtonRef.current &&
        !notificationCenterButtonRef.current.contains(event.target)
      ) {
        setShowNotificationCenter(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => document.removeEventListener("mousedown", handleMouseDown);
  }, []); // eslint-disable-line

  return (
    <>
      <StyledSidebar isExpanded={isExpanded} ref={sidebarRef} id="sidebar">
        <StyledMlyLogo>
          <Link to={routes.homePage}>
            <Icon type={IconType.mly} size={30} style={{ flexShrink: 0 }} color={Color.indigo50} />
            <StyledLogoName isExpanded={isExpanded}>
              <img src={mlyLogoText} alt="mly-logo-text" />
            </StyledLogoName>
          </Link>
        </StyledMlyLogo>
        <SidebarItem
          href={routes.homePage}
          icon={IconType.gauge}
          labelKey="sidebar.homepage"
          isExpanded={isExpanded}
        />
        <SidebarItem
          href={routes.analysisListPage}
          icon={IconType.barChart}
          labelKey="sidebar.analysisList"
          isExpanded={isExpanded}
        />
        <SidebarItem
          href={routes.userListPage}
          icon={IconType.hierarchy}
          show={isAnyAdmin(currentUser.roleType)}
          labelKey="sidebar.userList"
          isExpanded={isExpanded}
        />
        <SidebarItem
          icon={IconType.gearInGear}
          show={currentUser.roleType !== RoleType.Viewer}
          isActive={
            location.pathname.startsWith(routes.customizeVariableMappingsPage) ||
            location.pathname.startsWith(routes.customizeCustomModelsPage)
          }
          labelKey="sidebar.customizations"
          isExpanded={isExpanded}
          subMenuItems={customizeItems}
        />

        {IsFeatureActive(Feature.Redaction) && (
          <SidebarItem
            icon={IconType.lockedFile}
            labelKey="sidebar.redaction"
            isActive={
              location.pathname.startsWith(routes.redactionListPage) ||
              location.pathname.startsWith(routes.redactionRequestList) ||
              location.pathname.startsWith(routes.redactionVersionsList) ||
              location.pathname.startsWith(routes.createRedaction)
            }
            subMenuItems={redactionItems}
            isExpanded={isExpanded}
            displayRedDot
            notificationCount={redactionRequestCount > 0 && redactionRequestCount}
          />
        )}
        <SidebarItem
          href={routes.downloadedFilesPage}
          icon={IconType.export}
          labelKey="sidebar.downloadedFiles"
          isExpanded={isExpanded}
        />
        <SidebarItem
          href={routes.licensingPage}
          icon={IconType.windows}
          labelKey="sidebar.licensing"
          isExpanded={isExpanded}
          show={isAnyAdmin(currentUser.roleType)}
        />
        <StyledClickableExpandZone
          onMouseEnter={() => setShowExpandChevron(true)}
          onMouseLeave={() => setShowExpandChevron(false)}
          onClick={() => setIsExpanded((expand) => !expand)}
        >
          {(showExpandChevron || isExpanded) && (
            <StyledChevronContainer isExpanded={isExpanded}>
              <Icon type={IconType.chevronRight} color={Color.gray40} size={14} />
            </StyledChevronContainer>
          )}
        </StyledClickableExpandZone>
        <StyledSidebarBottomItems>
          <NotificationItem
            labelKey="notificationCenter.title"
            isExpanded={isExpanded}
            onClick={() => setShowNotificationCenter((show) => !show)}
            isActive={showNotificationCenter}
            unreadCount={
              notifications?.filter((n) => n.readStatus === NotificationReadStatus.Unread).length
            }
            ref={notificationCenterButtonRef}
          />
          <StyledAvatarItem>
            <UserProfileMenu isExpanded={isExpanded} />
          </StyledAvatarItem>
        </StyledSidebarBottomItems>
      </StyledSidebar>
      <NotificationCenter
        show={showNotificationCenter}
        leftOffset={sidebarRef?.current?.clientWidth}
        ref={notificationCenterRef}
        closeHandler={() => setShowNotificationCenter(false)}
      />
    </>
  );
};

const StyledSidebar = styled.div<{ isExpanded: boolean }>`
  background-color: ${Color.white};
  height: 100vh;
  width: ${({ isExpanded }) => (isExpanded ? "240px" : "64px")};
  border-right: 1px solid ${Color.blue20};
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${({ isExpanded }) =>
    isExpanded
      ? `calc(${ZIndexStackingContext.high} + 2)`
      : `calc(${ZIndexStackingContext.medium} + 3)`};
  display: ${APP_CSS["[#sidebar].display"]};
  flex-direction: column;
  padding: 16px 0;
  box-shadow: ${({ isExpanded }) => isExpanded && "0px 0px 10px 0px #0000001A"};
  transition: width 250ms ease-in-out, box-shadow 250ms ease-in-out;
`;

const StyledLogoName = styled.div<{ isExpanded: boolean }>`
  font-size: 1em;
  font-weight: bold;
  margin-left: 4px;
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  width: ${({ isExpanded }) => (isExpanded ? "70%" : "0%")};
  transition: visibility 250ms ease-in-out, opacity 250ms ease-in-out, width 150ms ease-in-out;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  color: ${Color.black};
  user-select: none;

  img {
    width: 34px;
    position: relative;
    left: 5px;
    top: 2px;
  }
`;

const StyledClickableExpandZone = styled.div`
  cursor: pointer;
  height: 100%;
`;

const StyledSidebarBottomItems = styled.div`
  margin-top: auto;
`;

const StyledAvatarItem = styled.div`
  margin-top: auto;
  margin-left: 6px;
`;

const StyledNotificationCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin-left: 6px;
  font-size: 10px;
  width: fit-content;
  height: 16px;
  color: ${Color.white};
  border-radius: 12px;
  background-color: ${Color.red50};
`;

const StyledChevronContainer = styled.div<{ isExpanded: boolean }>`
  background-color: ${Color.white};
  padding: 11px 2px;
  position: absolute;
  top: 50%;
  left: 100%;
  border-radius: 0px 2px 2px 0px;
  border-right: 1px solid ${Color.blue20};
  border-top: 1px solid ${Color.blue20};
  border-bottom: 1px solid ${Color.blue20};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  svg {
    transform: ${({ isExpanded }) => isExpanded && "rotate(180deg)"};
    transition: transform 200ms ease-in-out;
  }
`;

const StyledMlyLogo = styled.div`
  margin-bottom: 16px;
  a {
    display: flex;
    align-items: center;
    margin-left: 17px;
    width: calc(100% - 33px);
  }
`;

const StyledRequestsItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
