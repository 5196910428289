import React from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "store";
import {
  closeEditingRequest,
  setEditedRequest,
  setSelectedRequestId,
} from "store/redaction/redactionRequestManagementSlice";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { UserAvatar } from "components/UserAvatar.tsx";
import { RequestStatusPill } from "./RequestStatusPill";

import {
  StyledRedactedCommentHeaderSection,
  StyledRedactedCommentInput,
  StyledRedactionOptions,
  StyledRemovedCommentBanner,
} from "pages/analysis/[id]/comments/_layouts/Comment/RedactCommentModal";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { RedactionRequest } from "ts/redactionRequest";
import { RedactionDisplayMethod } from "@explorance/mly-types";

import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import { getUserFullName } from "utils/getUserFullName";
import { hashStringIntoNumber } from "utils/hashStringIntoNumber";
import { useResource } from "hooks/useResource";
import { RequestsView } from "ts/enums/requestListView";

type Props = {
  request: RedactionRequest;
  isSingle: boolean;
};

export const ManageableRequest = ({ request, isSingle }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.redactionRequestManagement);
  const redactionRequestListState = useAppSelector((state) => state.redactionRequestList);
  const { getResource } = useResource();

  const isEditing = state.editedRequest && state.editedRequest.requestId === request.requestId;
  const isSelected = state.selectedRequestId === request.requestId;

  const displayedRequestData = isEditing ? state.editedRequest : request;

  const isHidden = displayedRequestData.redactionDisplayMethod === RedactionDisplayMethod.Total;
  const isRemoved = displayedRequestData.redactionDisplayMethod === RedactionDisplayMethod.Remove;

  const userFullName = getUserFullName({
    firstname: request.requesterFirstname,
    lastname: request.requesterLastname,
  });

  const handleClickRequest = () => {
    if (isSingle) return;
    dispatch(setSelectedRequestId(request.requestId));
  };

  return (
    <StyledManageableRequest onClick={handleClickRequest}>
      {!isSingle && (
        <StyledRadioButtonContainer>
          <input type="radio" checked={isSelected} />
        </StyledRadioButtonContainer>
      )}
      <StyledRequestCard showSelectedUI={isSelected}>
        <StyledCardHeader>
          <StyledSubmitterInfo>
            <span>
              <Text resource="manageableRedactionRequestModal.[submitted]By" />
            </span>
            <UserAvatar
              firstName={request.requesterFirstname}
              lastName={request.requesterLastname}
              userId={hashStringIntoNumber(userFullName)}
            />
            {userFullName}
            <Text resource="term.on" />
            {getInternationalDateFormat(getDayMonthYearFromDate(request.requestSent))}
          </StyledSubmitterInfo>
          <StyledCardHeaderRightItems>
            <RequestStatusPill status={request.status} />
            {redactionRequestListState.listView === RequestsView.AllRequests && (
              <Button
                variant={isEditing ? ButtonVariant.secondary : ButtonVariant.outline}
                size={ButtonSize.sm}
                onClick={
                  isEditing
                    ? () => dispatch(closeEditingRequest())
                    : () => dispatch(setEditedRequest(request))
                }
              >
                <Text resource={isEditing ? "button.editing" : "button.edit"} />
              </Button>
            )}
          </StyledCardHeaderRightItems>
        </StyledCardHeader>
        {isEditing && (
          <StyledRedactedCommentHeaderSection>
            <h3>
              <Text resource="comments.redactModal.redactedComment.header" />
            </h3>
            <StyledRedactionOptions>
              <Button
                variant={isHidden ? ButtonVariant.secondary : ButtonVariant.outlineGray}
                size={ButtonSize.sm}
                onClick={() =>
                  dispatch(
                    setEditedRequest({
                      ...state.editedRequest,
                      redactionDisplayMethod:
                        state.editedRequest.redactionDisplayMethod === RedactionDisplayMethod.Total
                          ? RedactionDisplayMethod.Partial
                          : RedactionDisplayMethod.Total,
                    })
                  )
                }
              >
                <Icon type={IconType.eyeMinus} size={12} style={{ marginRight: 7 }} />
                <Text resource="comments.redactModal.button.hideComment" />
              </Button>
              <Button
                variant={isRemoved ? ButtonVariant.secondary : ButtonVariant.outlineGray}
                size={ButtonSize.sm}
                onClick={() =>
                  dispatch(
                    setEditedRequest({
                      ...state.editedRequest,
                      redactionDisplayMethod:
                        state.editedRequest.redactionDisplayMethod === RedactionDisplayMethod.Remove
                          ? RedactionDisplayMethod.Partial
                          : RedactionDisplayMethod.Remove,
                    })
                  )
                }
              >
                <Icon type={IconType.minusCircle} style={{ marginRight: 7 }} size={14} />
                <Text resource="comments.redactModal.button.removeComment" />
              </Button>
            </StyledRedactionOptions>
          </StyledRedactedCommentHeaderSection>
        )}
        {isRemoved ? (
          <StyledRemovedCommentBanner>
            <Text resource="comments.redactModal.redactedComment.removed" />
          </StyledRemovedCommentBanner>
        ) : (
          <StyledRedactedCommentInput
            value={
              isHidden
                ? getResource("comments.redactModal.redactedComment.redacted")
                : displayedRequestData.redactedValue
            }
            isWrapped={!isEditing}
            onChange={(e) =>
              dispatch(
                setEditedRequest({
                  ...state.editedRequest,
                  redactedValue: e.target.value,
                })
              )
            }
            isHidden={isHidden}
            disabled={isHidden || !isEditing}
          />
        )}
      </StyledRequestCard>
    </StyledManageableRequest>
  );
};

const StyledCardHeaderRightItems = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledManageableRequest = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
`;

const StyledRadioButtonContainer = styled.div`
  display: flex;
  align-items: center;

  input[type="radio"] {
    appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid ${Color.blue20};
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    background-color: ${Color.white};
  }

  input[type="radio"]:checked::after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: ${Color.blue50};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledRequestCard = styled.div<{ showSelectedUI: boolean }>`
  padding: 16px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid ${({ showSelectedUI }) => (showSelectedUI ? Color.blue50 : Color.blue20)};
  flex-grow: 1;
  transition: border-color 0.2s;
`;

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const StyledSubmitterInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  span {
    color: ${Color.gray50};
  }
`;
