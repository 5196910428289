import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentRedactionRequestApiData, RedactionRequest } from "ts/redactionRequest";
import { fetchRedactionRequestsForComment } from "./thunks";

type CommentRedactionRequestsState = {
  commentId: number;
  isModalOpen: boolean;
  requests: RedactionRequest[];
  totalRequestsCount: number;
  analysisName: string;
  selectedRequestId: number;
  editedRequest: RedactionRequest;
  isLoading: boolean;
};

const initialState: CommentRedactionRequestsState = {
  commentId: null,
  isModalOpen: false,
  requests: null,
  totalRequestsCount: null,
  analysisName: null,
  selectedRequestId: null,
  editedRequest: null,
  isLoading: true,
};

type OpenModalOptions =
  | {
      commentId: number;
      requestId: number;
      analysisName: string;
    }
  | {
      commentId: number;
      requestId: number;
      analysisName: string;
      redactionRequest: RedactionRequest;
    };

const redactionRequestManagementSlice = createSlice({
  name: "redactionRequestManagement",
  initialState,
  reducers: {
    setCommentId: (state, action: PayloadAction<number>) => {
      state.commentId = action.payload;
    },
    openModal: (state, action: PayloadAction<OpenModalOptions>) => {
      state.isModalOpen = true;
      state.commentId = action.payload.commentId;
      state.selectedRequestId = action.payload.requestId;
      state.analysisName = action.payload.analysisName;
      if ("redactionRequest" in action.payload) {
        state.requests = [action.payload.redactionRequest];
        state.totalRequestsCount = 1;
        state.isLoading = false;
      }
    },
    closeModal: () => initialState,
    setDataFromApi: (state, action: PayloadAction<CommentRedactionRequestApiData>) => {
      state.requests = action.payload.requests;
      state.totalRequestsCount = action.payload.totalCount;
    },
    setSelectedRequestId: (state, action: PayloadAction<number>) => {
      state.selectedRequestId = action.payload;
      if (action.payload !== state.editedRequest?.requestId) {
        state.editedRequest = null;
      }
    },
    setAnalysisName: (state, action: PayloadAction<string>) => {
      state.analysisName = action.payload;
    },
    setEditedRequest: (state, action: PayloadAction<RedactionRequest>) => {
      state.editedRequest = action.payload;
    },
    closeEditingRequest: (state) => {
      state.editedRequest = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedactionRequestsForComment.pending, (state) => {
      // This check ensures the loading state doesn't flash after modifying a request
      // since there's already a request in the state it's safe to keep the data visible
      // during the subsequent loading period
      if (!state.requests) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchRedactionRequestsForComment.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchRedactionRequestsForComment.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setCommentId,
  openModal,
  closeModal,
  setDataFromApi,
  setSelectedRequestId,
  setEditedRequest,
  closeEditingRequest,
  setAnalysisName,
} = redactionRequestManagementSlice.actions;

export default redactionRequestManagementSlice.reducer;
