import { uniqBy } from "lodash";
import { RedactionListType } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  editRedactionSet,
  fetchRedactionSet,
  fetchRedactionSetTerms,
  saveRedactionSet,
} from "./thunks";

type RemoveRedactionActionPayload = {
  redaction: string;
  index: number;
};

type SuggestedTerm = {
  term: string;
  isSelected: boolean;
};

type InitialRedactionSetType = {
  setName: string;
  terms: string[];
  includeVariations: boolean;
  termCount: number;
};

type CreateRedactionState = {
  blockRouteChange: boolean;
  isLoading: boolean;
  isSimilarTermsLoading: boolean;
  showSaveChangesModal: boolean;
  showDiscardChangesModal: boolean;
  hasBeenEdited: boolean;
  isVariationCheckboxChecked: boolean;
  isSelectAllSelected: boolean;
  noSimilarTermsFound: boolean;
  redactionFileName: string;
  inputtedRedactionList: string[];
  alreadySearchedTerms: string[];
  initialRedactionSet: InitialRedactionSetType;
  suggestedRelatedTerms: SuggestedTerm[];
  redactionType: RedactionListType;
  hasErrors: boolean;
  isValidToSave: boolean;
  redactionSetId: number;
  redactionTermCount: number;
  page: number;
};

const initialState: CreateRedactionState = {
  blockRouteChange: false,
  isLoading: false,
  isSimilarTermsLoading: false,
  hasBeenEdited: false,
  showSaveChangesModal: false,
  showDiscardChangesModal: false,
  isVariationCheckboxChecked: false,
  noSimilarTermsFound: false,
  redactionFileName: "New Redaction",
  redactionType: RedactionListType.User,
  isSelectAllSelected: false,
  inputtedRedactionList: [],
  alreadySearchedTerms: [],
  initialRedactionSet: null,
  suggestedRelatedTerms: [],
  hasErrors: false,
  isValidToSave: null,
  redactionSetId: null,
  redactionTermCount: 0,
  page: 1,
};

const CreateRedactionSlice = createSlice({
  name: "redactionBuilder",
  initialState,
  reducers: {
    setRedactionFileName: (state, action: PayloadAction<string>) => {
      state.hasBeenEdited = true;
      state.blockRouteChange = true;
      state.redactionFileName = action.payload;
    },
    setInitialRedactionSet: (state, action: PayloadAction<InitialRedactionSetType>) => {
      state.initialRedactionSet = action.payload;
    },
    setRedactionType: (state, action: PayloadAction<RedactionListType>) => {
      state.redactionType = action.payload;
      state.hasBeenEdited = true;
      state.blockRouteChange = true;
    },
    appendSuggestedRelatedTerms: (state, action: PayloadAction<string[]>) => {
      const newSuggestedRelatedTerms = [];
      action.payload.forEach((term) => {
        if (
          !state.suggestedRelatedTerms.some((suggestion) => suggestion.term === term) &&
          !state.inputtedRedactionList.some((item) => item === term)
        ) {
          newSuggestedRelatedTerms.push({ term, isSelected: false });
        }
      });
      state.suggestedRelatedTerms = uniqBy(
        state.suggestedRelatedTerms.concat(newSuggestedRelatedTerms),
        "term"
      );
      state.noSimilarTermsFound = state.suggestedRelatedTerms.length === 0;
    },
    incrementPage: (state) => {
      state.page++;
    },
    clearSuggestedRelatedTerms: (state) => {
      state.suggestedRelatedTerms = [];
      state.alreadySearchedTerms = [];
    },
    setIsSimilarTermsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSimilarTermsLoading = action.payload;
    },

    setInputtedRedactionList: (state, action: PayloadAction<string>) => {
      state.blockRouteChange = true;
      state.hasErrors = false;
      state.inputtedRedactionList.push(action.payload);
      state.redactionTermCount += 1;
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.map((suggestion) => {
        if (suggestion.term === action.payload) {
          return { term: suggestion.term, isSelected: true };
        }
        return suggestion;
      });

      if (
        state.suggestedRelatedTerms.length > 0 &&
        state.suggestedRelatedTerms.every((suggestion) => suggestion.isSelected)
      ) {
        state.isSelectAllSelected = true;
      }

      if (!state.hasBeenEdited && state.inputtedRedactionList.length > 0) {
        state.hasBeenEdited = true;
      }
    },
    addAlreadySearchedTerm: (state, action: PayloadAction<string>) => {
      state.alreadySearchedTerms.push(action.payload);
    },
    resetAlreadySearchedTerms: (state) => {
      state.alreadySearchedTerms = [];
    },
    setShowDiscardChangesModal: (state, action: PayloadAction<boolean>) => {
      state.showDiscardChangesModal = action.payload;
    },
    setShowSaveChangesModal: (state, action: PayloadAction<boolean>) => {
      state.showSaveChangesModal = action.payload;
    },
    setIsSelectAllSelected: (state, action: PayloadAction<boolean>) => {
      state.isSelectAllSelected = action.payload;
    },
    setIsVariationCheckboxChecked: (state, action: PayloadAction<boolean>) => {
      state.isVariationCheckboxChecked = action.payload;
      state.hasBeenEdited = true;
      state.blockRouteChange = true;
    },
    setHasErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    setBlockRouteChange: (state, action: PayloadAction<boolean>) => {
      state.blockRouteChange = action.payload;
    },
    setHasBeenEdited: (state, action: PayloadAction<boolean>) => {
      state.hasBeenEdited = action.payload;
    },
    setIsValidToSave: (state, action: PayloadAction<boolean>) => {
      state.isValidToSave = action.payload;
    },
    setRedactionSetId: (state, action: PayloadAction<number>) => {
      state.redactionSetId = action.payload;
    },

    discardChanges: (state) => {
      state.showDiscardChangesModal = false;
      state.hasBeenEdited = false;
      state.suggestedRelatedTerms = [];
      state.alreadySearchedTerms = [];
      state.redactionTermCount = state.initialRedactionSet.termCount;
      if (state.initialRedactionSet) {
        state.inputtedRedactionList = state.initialRedactionSet.terms;
        state.redactionFileName = state.initialRedactionSet.setName;
        state.isVariationCheckboxChecked = state.initialRedactionSet.includeVariations;
      } else {
        state.inputtedRedactionList = [];
        state.isVariationCheckboxChecked = false;
        state.redactionFileName = initialState.redactionFileName;
        state.redactionType = initialState.redactionType;
      }
      state.hasErrors = false;
      state.blockRouteChange = false;
    },
    addAllRedactionSuggestions: (state) => {
      state.hasBeenEdited = true;
      state.hasErrors = false;
      state.blockRouteChange = true;
      state.redactionTermCount += state.suggestedRelatedTerms.filter(
        (suggestedTerm) => !suggestedTerm.isSelected
      ).length;
      state.isSelectAllSelected = true;
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.map((suggestion) => {
        return { term: suggestion.term, isSelected: true };
      });

      const filteredItems = state.suggestedRelatedTerms.filter(
        (listItem) => !state.inputtedRedactionList.includes(listItem.term)
      );

      state.inputtedRedactionList = [
        ...state.inputtedRedactionList,
        ...filteredItems.map((suggestion) => suggestion.term),
      ];
    },
    removeAllRedactionSuggestions: (state) => {
      state.redactionTermCount -= state.suggestedRelatedTerms.length;
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.map((suggestion) => {
        return { term: suggestion.term, isSelected: false };
      });
      const suggestedTermsSet = new Set(state.suggestedRelatedTerms.map((term) => term.term));
      state.inputtedRedactionList = state.inputtedRedactionList.filter(
        (listItem) => !suggestedTermsSet.has(listItem)
      );
    },
    removeSelectedTermsFromSuggestionsList: (state) => {
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.filter(
        (termObject) => !state.inputtedRedactionList.includes(termObject.term)
      );
    },
    removeRedactionItem: (state, action: PayloadAction<RemoveRedactionActionPayload>) => {
      state.hasBeenEdited = true;
      state.blockRouteChange = true;
      state.inputtedRedactionList.splice(action.payload.index, 1);

      state.redactionTermCount -= 1;
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.map((suggestion) => {
        if (action.payload.redaction === suggestion.term) {
          return { term: suggestion.term, isSelected: false };
        }
        return suggestion;
      });
    },
    clearAll: (state) => {
      state.redactionTermCount = 0;
      state.blockRouteChange = true;
      state.hasBeenEdited = true;
      state.inputtedRedactionList = [];
      state.isSelectAllSelected = false;
      state.suggestedRelatedTerms = state.suggestedRelatedTerms.map((suggestion) => {
        return { term: suggestion.term, isSelected: false };
      });
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRedactionSet.fulfilled, (state, action) => {
      state.redactionFileName = action.payload.name;
      state.initialRedactionSet = {
        setName: action.payload.name,
        terms: action.payload.terms,
        includeVariations: action.payload.includeVariations,
        termCount: action.payload.termCount,
      };
      state.inputtedRedactionList = action.payload.terms;
      state.redactionTermCount = action.payload.termCount;
      state.redactionType = action.payload.type;
      state.isVariationCheckboxChecked = action.payload.includeVariations;
    });
    builder.addCase(saveRedactionSet.pending, (state) => {
      state.hasErrors = false;
      state.isLoading = true;
      state.blockRouteChange = false;
    });
    builder.addCase(saveRedactionSet.fulfilled, (state) => {
      state.isLoading = false;
      state.hasBeenEdited = false;
      state.initialRedactionSet = {
        setName: state.redactionFileName,
        terms: state.inputtedRedactionList,
        includeVariations: state.isVariationCheckboxChecked,
        termCount: state.inputtedRedactionList.length,
      };
    });
    builder.addCase(editRedactionSet.fulfilled, (state) => {
      state.isLoading = false;
      state.hasBeenEdited = false;
      state.initialRedactionSet = {
        setName: state.redactionFileName,
        terms: state.inputtedRedactionList,
        includeVariations: state.isVariationCheckboxChecked,
        termCount: state.inputtedRedactionList.length,
      };
    });

    builder.addCase(saveRedactionSet.rejected, (state) => {
      state.isLoading = false;
      state.isValidToSave = false;
      state.blockRouteChange = true;
    });
    builder.addCase(fetchRedactionSetTerms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRedactionSetTerms.fulfilled, (state, action) => {
      state.inputtedRedactionList = [...state.inputtedRedactionList, ...action.payload];
      state.isLoading = false;
    });
  },
});

export const {
  addAllRedactionSuggestions,
  clearAll,
  clearState,
  discardChanges,
  setIsVariationCheckboxChecked,
  setInitialRedactionSet,
  setShowSaveChangesModal,
  setRedactionFileName,
  setRedactionType,
  setInputtedRedactionList,
  addAlreadySearchedTerm,
  resetAlreadySearchedTerms,
  setHasBeenEdited,
  setBlockRouteChange,
  setIsSelectAllSelected,
  setRedactionSetId,
  setIsValidToSave,
  setShowDiscardChangesModal,
  incrementPage,
  setHasErrors,
  removeRedactionItem,
  removeAllRedactionSuggestions,
  appendSuggestedRelatedTerms,
  setIsSimilarTermsLoading,
  clearSuggestedRelatedTerms,
  removeSelectedTermsFromSuggestionsList,
} = CreateRedactionSlice.actions;

export default CreateRedactionSlice.reducer;
