import {
  RedactionAction,
  RedactionDisplayMethod,
  RedactionRequestStatus,
  SortingOrder,
} from "@explorance/mly-types";
import { getHeaders, mlyApi } from "config/mlyApi";
import { RequestsView } from "ts/enums/requestListView";
import { RedactionRequestListSortingParameter } from "ts/enums/sorting";
import { getUrlQueryString } from "utils/getUrlQueryString";

export async function getRedactionRequestList(
  view?: RequestsView,
  sort_column?: RedactionRequestListSortingParameter,
  sort_order?: SortingOrder,
  search?: string,
  limit?: number,
  page?: number,
  status?: string[]
) {
  const requestConfig = await getHeaders();
  const queryParams = {
    search,
    page,
    limit,
    view,
    ...(status.length !== 0 && { status: [status] }),
    sort_column,
    sort_order,
  };
  const url = "/redaction-requests" + getUrlQueryString(queryParams);
  return mlyApi.get(url, requestConfig);
}

export async function getRedactionRequestCount(
  only_count: boolean,
  status: RedactionRequestStatus
) {
  const requestConfig = await getHeaders();
  const url = "/redaction-requests" + getUrlQueryString({ only_count, status });
  return mlyApi.get(url, requestConfig);
}

type UpdateRedactionRequestBody = {
  redactionAction: RedactionAction;
  editedValue?: string;
  editedDisplayMethod?: RedactionDisplayMethod;
};

export const updateRedactionRequest = async (
  redactionId: number,
  body: UpdateRedactionRequestBody
) => {
  const requestConfig = await getHeaders();
  const url = `/redaction-requests/${redactionId}`;
  return mlyApi.patch(url, body, requestConfig);
};

type UpdateRedactionRequestsBody = {
  requestIds: number[];
  redactionAction: RedactionAction;
  editerValue?: string;
};

export const updateRedactionRequests = async (body: UpdateRedactionRequestsBody) => {
  const requestConfig = await getHeaders();
  const url = `/redaction-requests`;
  return mlyApi.patch(url, body, requestConfig);
};

export const getRedactionRequestsByCommentId = async (commentId: number) => {
  const requestConfig = await getHeaders();
  const url = `/comments/${commentId}/requests`;
  return mlyApi.get(url, requestConfig);
};

type CreateRedactionRequestBody = {
  commentId: number;
  redactedValue: string;
  redactionDisplayMethod: RedactionDisplayMethod;
};

export const createRedactionRequestsByCommentId = async (body: CreateRedactionRequestBody) => {
  const requestConfig = await getHeaders();
  const url = `/redaction-requests`;
  return mlyApi.post(url, body, requestConfig);
};
