import React, { useRef, useState } from "react";
import styled from "styled-components";

import { sleep } from "utils/sleep";
import { getRedactionSuggestions } from "services/redactions";
import { useAppDispatch, useAppSelector } from "store";
import {
  addAlreadySearchedTerm,
  appendSuggestedRelatedTerms,
  setIsSelectAllSelected,
  setIsSimilarTermsLoading,
  removeSelectedTermsFromSuggestionsList,
} from "store/redaction/redactionBuilderSlice";

import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { LoadingDots } from "components/LoadingDots";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ZIndexStackingContext } from "ts/enums/zIndexStackingContext";
import { showToastError } from "store/toast/toastSlice";
import { RedactionListType, RoleType } from "@explorance/mly-types";

const ESTIMATED_SUGGESTION_REQUEST_MS = 3000;
const INTERVAL_MS = 500;
const DEBOUNCE_MS = 100;

export const FindSuggestedTermsButton = () => {
  const state = useAppSelector((state) => state.redactionBuilder);
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector((state) => state.auth);

  const isRedactionSetReadOnly =
    currentUser.roleType === RoleType.Analyst && state.redactionType === RedactionListType.System;

  // Ref is needed to keep track of progress percentage during async calls.
  const [similarTermsProgressPercent, setSimilarTermsProgressPercent] = useState(-1);
  const similarTermsProgressPercentRef = useRef(-1);

  const updateSimilarTermsStateAndRef = (percent: number) => {
    setSimilarTermsProgressPercent(percent);
    similarTermsProgressPercentRef.current = percent;
  };

  const finalizeProgress = () => {
    updateSimilarTermsStateAndRef(100);
    dispatch(setIsSimilarTermsLoading(false));
    setTimeout(() => {
      updateSimilarTermsStateAndRef(-1);
    }, 2 * INTERVAL_MS);
  };

  const findSuggestedTerms = async () => {
    updateSimilarTermsStateAndRef(0);
    dispatch(setIsSimilarTermsLoading(true));

    dispatch(removeSelectedTermsFromSuggestionsList());

    if (state.inputtedRedactionList.every((term) => state.alreadySearchedTerms.includes(term))) {
      await sleep(50); // This leaves time for the bar to start animating
      finalizeProgress();
      return;
    }

    // Calculate how much progress percentage each request represents
    const totalRequestsCount = state.inputtedRedactionList.length;
    const percentNeededUntilNextCall = 100 / totalRequestsCount;

    // This grouping ensures the progress bar starts smoothly and becomes faster as it progresses
    const termsToLookup = [
      ...state.inputtedRedactionList.filter((term) => !state.alreadySearchedTerms.includes(term)),
      ...state.inputtedRedactionList.filter((term) => state.alreadySearchedTerms.includes(term)),
    ];

    let progressInterval;

    try {
      // Iterate through each redaction term in the input list. 1 iteration = 1 API call
      for (let i = 0; i < totalRequestsCount; i++) {
        // Calculate the target percentage for the next API call
        const nextCallPercent = ((i + 1) / totalRequestsCount) * 100;

        // Gradually increment progress bar until next iteration
        progressInterval = setInterval(() => {
          setSimilarTermsProgressPercent((prev) => {
            const newPercent =
              prev + percentNeededUntilNextCall / (ESTIMATED_SUGGESTION_REQUEST_MS / INTERVAL_MS);
            similarTermsProgressPercentRef.current = newPercent;
            if (newPercent < nextCallPercent) return newPercent;
            return prev;
          });
        }, INTERVAL_MS);

        // Call the API only if the term has not been searched
        if (!state.alreadySearchedTerms.includes(termsToLookup[i])) {
          const { data } = await getRedactionSuggestions([termsToLookup[i]]);
          dispatch(addAlreadySearchedTerm(termsToLookup[i]));
          dispatch(appendSuggestedRelatedTerms(data.suggestions));
          // Reset "select all" pill if suggestions are found, to avoid confusion in UI
          if (data.suggestions.length > 0 && state.isSelectAllSelected) {
            dispatch(setIsSelectAllSelected(false));
          }
          await sleep(DEBOUNCE_MS); // Debounce API calls to avoid rate limiting
        }

        // Ensure progress bar reaches the correct percentage for this iteration
        if (similarTermsProgressPercentRef.current < nextCallPercent) {
          setSimilarTermsProgressPercent(nextCallPercent);
        }

        clearInterval(progressInterval);
      }
    } catch (err: any) {
      console.error(err.message);
      dispatch(showToastError("toast.defaultError"));
    } finally {
      finalizeProgress();
    }
  };

  return (
    <StyledFindSuggestedTermsButton hideText={similarTermsProgressPercent !== -1}>
      <StyledProgressBarOverlay widthPercent={similarTermsProgressPercent} />

      <Button
        variant={ButtonVariant.primaryPurple}
        size={ButtonSize.ml}
        onClick={findSuggestedTerms}
        disabled={
          state.inputtedRedactionList.length === 0 ||
          similarTermsProgressPercent !== -1 ||
          isRedactionSetReadOnly
        }
      >
        <Icon
          type={IconType.search}
          color={Color.neutral40}
          size={12}
          style={{ marginRight: "6px" }}
        />
        <Text resource="redaction.button.similarTerms" />
      </Button>
      {similarTermsProgressPercent !== -1 && (
        <StyledLoadingDotsOverlay>
          <StyledDotsPill>
            <LoadingDots />
          </StyledDotsPill>
        </StyledLoadingDotsOverlay>
      )}
    </StyledFindSuggestedTermsButton>
  );
};

const StyledFindSuggestedTermsButton = styled.span<{ hideText: boolean }>`
  position: relative;
`;

const StyledProgressBarOverlay = styled.div<{ widthPercent: number }>`
  position: absolute;
  background-color: ${Color.gray50};
  width: ${({ widthPercent }) => widthPercent}%;
  max-width: 100%;
  height: 100%;
  z-index: ${ZIndexStackingContext.low + 1};
  left: 0;
  opacity: 0.6;
  transition: width 1s ease-out, opacity 1s ease-out;
`;

const StyledLoadingDotsOverlay = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: ${ZIndexStackingContext.low + 2};
`;

const StyledDotsPill = styled.div`
  background-color: ${Color.white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 30px;
  box-shadow: 0px 2px 10px ${Color.shadow10};
`;
