import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { io } from "socket.io-client";
import { isEqual } from "lodash";

import { APP_CSS } from "assets/constants/appCss";
import { WELCOME_SCREEN_CLOSE_DURATION } from "assets/constants/welcomeScreenCloseDuration";
import { Color } from "ts/enums/color";
import { AnalysisPageType } from "ts/enums/analysisPageType";
import { SocketEvent } from "@explorance/mly-types";

import { authenticationManager } from ".";
import { routes, Routes } from "routes";
import { config } from "config/configProviders";

import { useAppSelector, useAppDispatch } from "store";
import { setNotifications, setSocket } from "store/wsStream/wsSlice";
import { setCurrentUser } from "store/auth/authSlice";
import { setResources, setTopicResources } from "store/resources/resourceSlice";

import { LoadingDots } from "components/LoadingDots";
import { ScreenSizeNotSupported } from "components/_screens/ScreenSizeNotSupported";
import { Sidebar } from "components/Sidebar";
import { Toast } from "components/Toast";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";
import { WelcomeScreen } from "components/_screens/WelcomeScreen";
import { ResourcesProvider } from "context/ResourcesContext";
import {
  fetchRecentTopicsResourceFile,
  getResourceFiles,
  getResources,
} from "context/ResourcesContext/helpers";

import { getCurrentUserId } from "hooks/useGetUserId";
import { getTypedObjectKeys } from "utils/keys";
import { getUser } from "services/users";
import { getNotifications } from "services/notifications";
import { setFeatures } from "store/appSettings/settingsSlice";
import { fetchRedactionFeatureStatus } from "services/settings";

export const App = () => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const resourcesState = useAppSelector((state) => state.resourcesSlice);
  const { features } = useAppSelector((state) => state.settings);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState<boolean>(false);

  const { pathname } = useLocation();
  const history = useHistory();

  const mainContentRef = useRef<HTMLDivElement>();
  const socketInitializedRef = useRef<boolean>(false);
  const resourcesFiles = getResourceFiles();

  const isGrayBackground: boolean =
    pathname.startsWith(routes.updateProfilePath) ||
    pathname === routes.createProfilePage ||
    pathname === routes.homePage ||
    pathname.includes("overview") ||
    pathname.includes("dashboard");

  useEffect(() => {
    if (history.location.search.includes("?show-welcome-screen")) {
      setIsWelcomeScreenOpen(true);
    }
  }, [history.location.search]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || action === "REPLACE") {
        localStorage.setItem("lastVisitedPath", location.pathname);
      }
    });

    return () => unlisten();
  }, [history]);

  useEffect(() => {
    const initUser = async () => {
      setIsLoading(true);

      const isLoggedIn = await authenticationManager.isLoggedIn();

      if (!isLoggedIn) {
        authenticationManager.login();
        return;
      }

      const userId = await getCurrentUserId();

      if (userId) {
        const { data } = await getUser(userId);
        dispatch(setCurrentUser(data));
      }

      setIsLoading(false);
    };

    initUser();
  }, [dispatch]);

  //fetch feature settings
  useEffect(() => {
    fetchRedactionFeatureStatus().then(({ data }) => {
      dispatch(setFeatures({ ...features, redaction: data.isActive }));
    });
  }, []); //eslint-disable-line

  useEffect(() => {
    // Only initialize socket if currentUser is available and socket is not already initialized
    if (currentUser && !socketInitializedRef.current) {
      const newSocket = io(config.server.baseApi);
      newSocket.on("connect", () => {
        newSocket.emit(SocketEvent.UserConnected, currentUser.id);
      });
      dispatch(setSocket(newSocket));
      socketInitializedRef.current = true;

      return () => {
        newSocket.off("connect");
        newSocket.disconnect();
        socketInitializedRef.current = false;
      };
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    getNotifications().then(({ data }) => {
      dispatch(setNotifications(data.notifications));
    });
  }, [dispatch]);

  // initialize resources
  useEffect(() => {
    if (
      currentUser &&
      (Object.keys(resourcesState.topicResources.Eng).length === 0 || !resourcesState.resources)
    ) {
      getTypedObjectKeys(resourcesFiles).forEach(async (lang) => {
        const recentTopicsResourceFile = await fetchRecentTopicsResourceFile(lang);

        // Check if topic translations in the state are outdated vs. blob file.
        if (!isEqual(resourcesState.topicResources[lang], recentTopicsResourceFile)) {
          localStorage.setItem(`topics_${lang}`, JSON.stringify(recentTopicsResourceFile));
          const resources = getResources({
            ...resourcesState.topicResources,
            [lang]: recentTopicsResourceFile,
          });
          dispatch(
            setTopicResources({
              ...resourcesState.topicResources,
              [lang]: recentTopicsResourceFile,
            })
          );
          dispatch(setResources(resources));
        }
      });
    } else if (!resourcesState.resources) {
      const resources = getResources(resourcesState.topicResources);
      dispatch(setResources(resources));
    }
  }, [currentUser, resourcesState.topicResources, dispatch]); //eslint-disable-line

  // reset scroll position for navigating between analysis pages
  useEffect(() => {
    const analysisPages = Object.keys(AnalysisPageType).map((apt) => apt.toLowerCase());

    if (analysisPages.some((ap) => pathname.includes(ap)) && mainContentRef.current) {
      mainContentRef.current.scrollIntoView();
    }
  }, [pathname]);

  const handleCloseWelcomeScreen = () => {
    setTimeout(() => setIsWelcomeScreenOpen(false), WELCOME_SCREEN_CLOSE_DURATION);
  };

  if (!currentUser) return <LoadingDotsScreen />;

  return (
    <ResourcesProvider>
      {isLoading && (
        <StyledLoadingContainer>
          <div className="loading-dots">
            <LoadingDots />
          </div>
        </StyledLoadingContainer>
      )}
      <StyledMasterPage>
        {isWelcomeScreenOpen && <WelcomeScreen handleClose={handleCloseWelcomeScreen} />}
        <ScreenSizeNotSupported />
        <Sidebar />
        <div className={`main-body ${isGrayBackground ? "bg-gray" : ""}`}>
          <div ref={mainContentRef} className="main-content">
            <Routes />
          </div>
        </div>
      </StyledMasterPage>
      <Toast />
    </ResourcesProvider>
  );
};

const StyledLoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .loading-dots {
    margin: auto;
  }
`;

const StyledMasterPage = styled.div`
  display: flex;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  color: ${Color.gray50}; // Default text color through the app

  .main-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    transition: background 100ms ease-in-out 50ms;
    /* Prevents scrollbar from pushing content */
    scrollbar-gutter: stable;
    -webkit-scrollbar: 10px;
    -webkit-scrollbar-position: inside;
  }

  .bg-gray {
    background: ${Color.neutral10};
  }

  .main-content {
    margin: 0px auto;
    position: relative;
    left: ${APP_CSS["[.main-content].left"]};
  }

  @media (max-width: 1079px) {
    div:not(.notSupported, .notSupported div) {
      display: none;
    }
  }
`;

export default App;
