import React, { useState } from "react";
import styled from "styled-components";

import { Link } from "react-router-dom";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { Color } from "ts/enums/color";
import { DropdownMenuItem } from "ts/dropdown";
import { DropdownMenu } from "components/DropdownMenu";

type SidebarLinkProps = {
  href?: string;
  hrefs?: string[];
  show?: boolean;
  icon: IconType;
  labelKey: string;
  isExpanded: boolean;
  isActive?: boolean;
  displayRedDot?: boolean;
  notificationCount?: number;
  subMenuItems?: DropdownMenuItem[];
  onClick?: () => void;
  onMouseEnter?: () => void;
};

const SidebarItem = ({
  href,
  show = true,
  icon,
  labelKey,
  isExpanded,
  isActive,
  displayRedDot,
  notificationCount,
  subMenuItems,
  onClick,
  onMouseEnter,
}: SidebarLinkProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState(false);

  if (!show) return null;
  isActive = isActive || location.pathname.startsWith(href);

  const leaveSidebar = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 75);
  };

  const handleMouseEnter = () => {
    onMouseEnter && onMouseEnter();
    setIsHovered(true);
    setShowDropdown(true);
  };

  return (
    <StyledDropdownContainer
      onMouseLeave={() => {
        leaveSidebar();
      }}
    >
      <StyledSidebarItem
        isActive={isActive}
        isExpanded={isExpanded}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHovered(false)}
      >
        <ConditionalWrapper
          wrapper={(children) => <Link to={href}>{children}</Link>}
          condition={!!href}
        >
          <StyledIconContainer>
            <Icon
              type={icon}
              size={14}
              color={isActive ? Color.indigo45 : isHovered ? Color.gray50 : Color.gray20}
            />
            {displayRedDot && notificationCount ? <StyledCircle /> : null}
          </StyledIconContainer>
          <StyledExpandedNameContainer isActive={isActive} isExpanded={isExpanded}>
            <Text resource={labelKey} />
            {!href && <Icon type={IconType.chevronRight} color={Color.gray40} size={14} />}
          </StyledExpandedNameContainer>
        </ConditionalWrapper>
      </StyledSidebarItem>
      {subMenuItems && (
        <DropdownMenu
          items={subMenuItems}
          show={showDropdown}
          position={{ top: 8, right: -211 }}
          width="210px"
          customFontSize={14}
        />
      )}
    </StyledDropdownContainer>
  );
};

const StyledIconContainer = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: relative;
  svg * {
    transition: fill 50ms ease-in-out;
  }
`;

const StyledCircle = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  left: 34px;
  border: 1px solid ${Color.white};
  background-color: ${Color.red50};
  border-radius: 50%;
`;

const StyledSidebarItem = styled.div<{
  isActive: boolean;
  isExpanded: boolean;
}>`
  background-color: ${({ isActive }) => (isActive ? Color.sky15 : "none")};
  border-radius: 5px;
  width: ${({ isExpanded }) => (isExpanded ? "204px" : "28px")};
  height: 28px;
  display: flex;
  align-items: center;
  margin: 8px auto;
  cursor: pointer;
  padding-right: ${({ isExpanded }) => (isExpanded ? "6px" : "0")};
  transition: background-color 50ms ease-in-out, width 250ms ease-in-out;
  &:hover {
    background-color: ${Color.indigo10};
    transition: background-color 50ms ease-in-out;

    span {
      color: ${({ isActive }) => !isActive && Color.gray50};
      transition: color 50ms ease-in-out;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const StyledExpandedNameContainer = styled.div<{
  isExpanded: boolean;
  isActive: boolean;
}>`
  display: flex;
  align-items: center;
  opacity: ${({ isExpanded }) => (isExpanded ? "1" : "0")};
  position: relative;
  left: 10px;
  visibility: ${({ isExpanded }) => (isExpanded ? "visible" : "hidden")};
  width: ${({ isExpanded }) => (isExpanded ? "80%" : "0px")};
  transition: visibility 150ms ease-in-out 150ms, opacity 150ms ease-in-out 150ms,
    width 250ms ease-in-out;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;

  span {
    color: ${({ isActive }) => (isActive ? Color.indigo45 : Color.gray20)};
    font-size: 0.875em;
    padding-right: 55px;
    font-weight: ${({ isActive }) => isActive && "bold"};
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
    transition: color 50ms ease-in-out;
  }

  svg {
    margin-top: 1px;
  }
`;

const StyledDropdownContainer = styled.div`
  position: relative;
`;
export { SidebarItem };
