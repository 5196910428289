import React from "react";
import styled from "styled-components";
import { RedactionRequestStatus } from "@explorance/mly-types";
import { Text } from "components/Text";
import { useAppSelector } from "store";
import { TextTruncator } from "components/TextTruncator";
import { UserAvatar } from "components/UserAvatar.tsx";
import { getDayMonthYearFromDate } from "utils/formatters";
import { getInternationalDateFormat } from "utils/getInternationalDateFormat";
import { getUserFullName } from "utils/getUserFullName";
import { hashStringIntoNumber } from "utils/hashStringIntoNumber";
import { Color } from "ts/enums/color";

export const ApprovalInfo = () => {
  const state = useAppSelector((state) => state.redactionRequestManagement);

  if (!state.requests) return null;

  const mainApprovedRequest = state.requests.find(
    (request) => request.status === RedactionRequestStatus.Approved
  );

  const mainRejectedRequest =
    !mainApprovedRequest &&
    state.requests.find((request) => request.status === RedactionRequestStatus.Rejected);

  const mainReviewedRequest = mainApprovedRequest || mainRejectedRequest;

  const mainReviewedRequestUserFullName =
    mainReviewedRequest &&
    getUserFullName({
      firstname: mainReviewedRequest.reviewerFirstname,
      lastname: mainReviewedRequest.reviewerLastname,
    });

  return (
    <StyledApprovalInfo>
      <span>
        <Text resource={`manageableRedactionRequestModal.[${mainReviewedRequest.status}]By`} />
      </span>
      <UserAvatar
        firstName={mainReviewedRequest.reviewerFirstname}
        lastName={mainReviewedRequest.reviewerLastname}
        userId={hashStringIntoNumber(mainReviewedRequestUserFullName)}
      />
      <TextTruncator value={mainReviewedRequestUserFullName} customWidth="200px" />
      <Text resource="term.on" />
      <span>
        {getInternationalDateFormat(getDayMonthYearFromDate(mainReviewedRequest.requestSent))}
      </span>
    </StyledApprovalInfo>
  );
};

export const StyledApprovalInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  span,
  mly-text {
    color: ${Color.gray50};
  }
`;
