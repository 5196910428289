import {
  DemographicFilterMethod,
  ManualRedactionPermission,
  PermissionLevel,
  ShareableUsersSortingParameter,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingMethod,
  SharingOverviewAccess,
  SharingTopicExplorerAccess,
  SortingOrder,
} from "@explorance/mly-types";
import { getHeaders, mlyApi } from "config/mlyApi";
import {
  DemographicCustomCondition,
  RedactionCustomCondition,
  SharingUserDemographic,
} from "ts/sharing";
import { getUrlQueryString } from "utils/getUrlQueryString";
import { omit } from "lodash";
import { DefaultWidgets } from "ts/enums/sharing";

export type GetRecipientsGroupsParams = {
  analysisId: number;
  page?: number;
  limit?: number;
  sort_column?: string;
  sort_order?: SortingOrder;
  group_name?: string;
  search?: string;
};

export async function getRecipientsGroups(params: GetRecipientsGroupsParams) {
  const requestConfig = await getHeaders();
  const { analysisId, page, sort_column, group_name, sort_order, limit, search } = params;
  const queryString = getUrlQueryString({
    sort_column,
    group_name,
    sort_order,
    page,
    limit,
    search,
  });
  const url = `analysis/${analysisId}/sharing/recipients/groups${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export type GetRecipientsUsersParams = {
  analysisId: number;
  page?: number;
  limit?: number;
  sort_column?: string;
  sort_order?: SortingOrder;
  search?: string;
};

export async function getRecipientsUsers(params: GetRecipientsUsersParams) {
  const requestConfig = await getHeaders();
  const { analysisId, page, search, sort_column, sort_order, limit } = params;
  const queryString = getUrlQueryString({ page, search, sort_column, sort_order, limit });
  const url = `analysis/${analysisId}/sharing/recipients/users${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export type GetShareableUsersParams = {
  analysisId: number;
  page?: number;
  limit?: number;
  sort_column?: string;
  sort_order?: SortingOrder;
  search?: string;
};

export async function getShareableUsers(
  params: GetShareableUsersParams,
  demographics?: SharingUserDemographic[]
) {
  const requestConfig = await getHeaders();
  const { analysisId, page, limit, search, sort_column, sort_order } = params;
  const body = { demographics };
  const queryString = getUrlQueryString({ page, limit, search, sort_column, sort_order });
  const url = `analysis/${analysisId}/sharing/users${queryString}`;
  return mlyApi.post(url, body, requestConfig);
}

export type GetGroupsSharingDraftUsersParams = {
  sharingId: number;
  page?: number;
  limit?: number;
  sort_column?: ShareableUsersSortingParameter;
  sort_order?: SortingOrder;
  search?: string;
};

export async function getUsersInSharingDraft(params: GetGroupsSharingDraftUsersParams) {
  const requestConfig = await getHeaders();
  const { sharingId, page, limit, sort_column, sort_order, search } = params;
  const queryString = getUrlQueryString({ page, limit, sort_column, sort_order, search });
  const url = `/sharing/${sharingId}/draft/users${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export async function getSharingDraft(analysisId: number, method: SharingMethod) {
  const requestConfig = await getHeaders();
  const queryString = getUrlQueryString({ method });
  const url = `analysis/${analysisId}/sharing/draft${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export async function unshare(sharingId: number) {
  const requestConfig = await getHeaders();
  const url = `/sharing/${sharingId}`;
  return mlyApi.delete(url, requestConfig);
}

export type GetSharedUsersParams = {
  analysisId: number;
  page?: number;
  limit?: number;
};

export async function getSharedUsers(params: GetSharedUsersParams) {
  const requestConfig = await getHeaders();
  const { analysisId, page, limit } = params;
  const queryString = getUrlQueryString({ page, limit });
  const url = `analysis/${analysisId}/sharing/draft/users${queryString}`;
  return mlyApi.get(url, requestConfig);
}

export async function saveSharing(analysisId: number) {
  const requestConfig = await getHeaders();
  const url = `analysis/${analysisId}/sharing`;
  return mlyApi.post(url, {}, requestConfig);
}

type GetSharingDraftUsersParams = {
  analysisId: number;
  page?: number;
  limit?: number;
};

export async function getSharingDraftUsers(params: GetSharingDraftUsersParams) {
  const requestConfig = await getHeaders();
  const { analysisId, page, limit } = params;
  const queryString = getUrlQueryString({ page, limit });
  const url = `analysis/${analysisId}/sharing/draft/users${queryString}`;
  return mlyApi.get(url, requestConfig);
}

type AddDeleteUsersToSharingDraftParams = {
  analysisId?: number;
  sharingMethod: SharingMethod;
  userIds: number[];
  sharingId?: number;
  groupName?: string;
  isDemographicGroup?: boolean;
  definition?: JSON;
  removeAll?: boolean;
};

export async function addUsersToNewSharingDraft({
  analysisId,
  sharingMethod,
  groupName,
  isDemographicGroup,
  definition,
  userIds,
}: AddDeleteUsersToSharingDraftParams) {
  const requestConfig = await getHeaders();
  const requestBody = {
    sharingMethod,
    groupName,
    isDemographicGroup,
    definition,
    userIds,
  };
  const url = `analysis/${analysisId}/sharing/draft/users`;
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function removeUsersFromNewSharingDraft({
  analysisId,
  userIds,
  removeAll,
}: AddDeleteUsersToSharingDraftParams) {
  const requestConfig = await getHeaders();
  if (!removeAll) {
    requestConfig.data = {
      userIds,
    };
  }
  const queryString = getUrlQueryString({ remove_all: removeAll });
  const url = `analysis/${analysisId}/sharing/draft/users${queryString}`;
  return mlyApi.delete(url, requestConfig);
}

type UpdateSharingDraftParams = {
  analysisId: number;
  method: SharingMethod;
  groupName: string;
  permissionLevel: PermissionLevel;
  // TO DO: update DemographicFilter in MLYTypes to match what is listed below
  demographicFilters: {
    id: number;
    name: string;
    method: DemographicFilterMethod;
    values: string[];
  }[];
  customFilters: DemographicCustomCondition[];
  demographicsToDisplay: string[];
  allowExport: boolean;
  overviewAccess: SharingOverviewAccess;
  dashboardAccess: SharingDashboardAccess;
  topicExplorerAccess: SharingTopicExplorerAccess;
  commentExplorerAccess: SharingCommentExplorerAccess;
  defaultWidgets: DefaultWidgets;
  redactionVersionId: number;
  customRedactionVersions: RedactionCustomCondition[];
  allowPin: boolean;
};

export async function updateSharingDraft(params: UpdateSharingDraftParams) {
  const requestConfig = await getHeaders();
  const requestBody = omit(params, ["analysisId"]);
  const url = `analysis/${params.analysisId}/sharing/draft`;
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function getSharingDraftBySharingId(sharingId: number, overwrite: boolean) {
  const requestConfig = await getHeaders();
  const url = `sharing/${sharingId}/draft?overwrite=${overwrite}`;
  return mlyApi.get(url, requestConfig);
}

type UpdateSharingDraftBySharingIdParams = {
  sharingId: number;
  groupName: string;
  demographicFilters: {
    id: number;
    name: string;
    method: DemographicFilterMethod;
    values: string[];
  }[];
  customFilters: DemographicCustomCondition[];
  demographicsToDisplay: string[];
  allowExport: boolean;
  overviewAccess: SharingOverviewAccess;
  topicExplorerAccess: SharingTopicExplorerAccess;
  commentExplorerAccess: SharingCommentExplorerAccess;
  dashboardAccess: SharingDashboardAccess;
  defaultWidgets: DefaultWidgets;
  method: SharingMethod;
  permissionLevel: PermissionLevel;
  redactionVersionId: number;
  customRedactionVersions: RedactionCustomCondition[];
  manualRedactionPermission: ManualRedactionPermission;
  allowPin: boolean;
};

export async function updateSharingDraftBySharingId(params: UpdateSharingDraftBySharingIdParams) {
  const requestConfig = await getHeaders();
  const requestBody = omit(params, ["sharingId"]);
  const url = `sharing/${params.sharingId}/draft`;
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function saveSharingBySharingId(sharingId: number) {
  const requestConfig = await getHeaders();
  const url = `sharing/${sharingId}`;
  return mlyApi.post(url, {}, requestConfig);
}

export async function addUsersToExistingSharingDraft({
  sharingId,
  userIds,
  sharingMethod,
}: AddDeleteUsersToSharingDraftParams) {
  const requestConfig = await getHeaders();
  const requestBody = {
    userIds,
    sharingMethod,
  };
  const url = `sharing/${sharingId}/draft/users`;
  return mlyApi.put(url, requestBody, requestConfig);
}

export async function removeUsersFromExistingSharingDraft({
  sharingId,
  userIds,
}: AddDeleteUsersToSharingDraftParams) {
  const requestConfig = await getHeaders();
  requestConfig.data = {
    userIds,
  };
  const url = `sharing/${sharingId}/draft/users`;
  return mlyApi.delete(url, requestConfig);
}

export async function pushWidgetChangesToSharees(analysisId) {
  const requestConfig = await getHeaders();
  const url = `analysis/${analysisId}/sharing/widgets`;
  return mlyApi.put(url, requestConfig);
}
